import React from 'react'
import { Avatar, Stars, PreviewBlock, CardList } from 'planetyze-ui'
import { Link } from 'libs/router'

const Review = (props) => {
  const { title, user, content, rate, created_at, guidebook, url, t } = props
  return (
    <div className='p-4 flex mb-12 shadow overflow-hidden'>
      {user && (
        <div className='hidden md:block mr-8 text-center'>
          <Avatar
            label={user.first_name}
            src={user.image ? user.image.url : null}
          />
        </div>
      )}
      <div className='w-full'>
        <div className='mb-2 flex items-center'>
          <Stars rate={rate} className='mr-2' />
          <div className='text-sm text-grey-dark' children={created_at} />
        </div>
        <Link
          to={url}
          className='text-xl mb-4 block text-black font-bold hover:text-primary hover:underline'>
          “{title}”
        </Link>
        <PreviewBlock
          className='text-base leading-normal text-grey-darkest'
          height='120px'
          children={content}
          t={t}
        />
        {guidebook && (
          <CardList
            className='mt-8 border border-grey p-2'
            title={guidebook.title}
            description={guidebook.description}
            url={guidebook.url}
            src={guidebook.image ? guidebook.image.url : null}
          />
        )}
      </div>
    </div>
  )
}

Review.defaultProps = {
  t: (t) => t
}

export default Review
