import en from './locales/en'
import es from './locales/es'
import ja from './locales/ja'
import zhHans from './locales/zh-hans'
import zhHant from './locales/zh-hant'

export default {
  en,
  es,
  ja,
  'zh-hans': zhHans,
  'zh-hant': zhHant
}
