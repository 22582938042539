module.exports = {
  // Global
  japan: "Japan",

  // Common Keywords
  common: {
    login: "Log In",
    logout: "Log Out",
    blogs: "Blogs",
    "not-found": "Page Not Found 404",
    orientation: "Orientation",
    itineraries: "Itineraries",
    access: "Access",
    "read-more": "read more",
    "show-all": "Show All",
    "no-results": "No results for this page",
    "search-no-results": "No results for",
    "private-tours": "Private Tours",
    "private-tour-guides": "Private Tour Guides"
  },

  // Pages
  home: {
    title: "Japan Travel Guidebook",
    description: "Original videos and articles on the best Japan destinations"
  },

  destinations: {
    title: "Destinations"
  },

  guidebook: {
    overview: "Overview",
    "photos-videos": "Photos & Videos",
    itineraries: "Itineraries",
    reviews: "Reviews",
    nearby: "NearBy",
    address: "Address",
    website: "Website",
    "nearby-spots": "NearBy Spots",
    "business-hours": "Business Hours",
    price: "Price",
    phone: "Phone",
    about: "About",
    access: "Access"
  },

  itinerary: {
    day: "Day :number"
  },

  itineraries: {
    title: "Itineraries"
  },

  blogs: {
    title: "Blogs & Articles in :location"
  },

  about: {
    title: "About Us",
    description:
      "Planetyze is the largest Japan travel guidebook, featuring videos, reviews, and articles about sightseeing spots from all over Japan.",
    lead:
      "You can discover the sightseeing spots that you're interested in, by watching short videos and reading reviews written by other Travellers."
  },

  company: {
    title: "Company",
    name: {
      label: "Company Name",
      value: "Travelience Inc."
    },
    website: {
      label: "Website",
      value: "http://travelience.jp"
    },
    address: {
      label: "Address",
      value:
        "Kuramae Nagatani Bldg 5F-C, 2-4-3 Kuramae, Taito-ku, Tokyo, Japan 111-0051"
    },
    phone: {
      label: "Phone",
      value: "+81 (0)3-6830-0896"
    },
    established: {
      label: "Established",
      value: "February 2013"
    },
    ceo: {
      label: "CEO",
      value: "Naoaki Hashimoto"
    },
    services: {
      label: "Services",
      value: [
        "Tour Guide Business for Foreigners Visiting japan",
        "Tourism Platform Business",
        "Regional Revitalization Consulting Business"
      ]
    }
  },

  contact: {
    title: "Contact Us",
    description: "How can we help?",
    success: "Your message was sent!"
  },

  auth: {
    profile: {
      title: "Profile",
      success: "Profile Updated"
    },
    password: {
      title: "Password",
      success: "Password Changed"
    },
    reviews: {
      title: "Reviews"
    },
    wishlist: {
      title: "Wishlist"
    }
  },

  login: {
    title: "Sign In",
    description: "Organize your research & unlock tools like wishlist.",
    "forgot-password": "Forgot your password?",
    "have-account": "Don't have an account?",
    signup: "Sign Up"
  },

  // Website Blocks

  blocks: {
    destinations: "Destinations",
    "popular-itineraries": "Popular Itineraries",
    "popular-category": "Popular :category",
    "blogs-articles-location": "Blogs & Articles in -:location",
    categories: "Categories",
    "related-itineraries": "Related Itineraries",
    "all-category": "All :category"
  },

  buttons: {
    save: "Save",
    submit: "Submit",
    "play-video": "Play Video",
    back: "Back",
    close: "Close",
    login: "Log In",
    "view-gallery": "View Gallery",
    "wishlist-remove": "Remove from Wishlist",
    "wishlist-add": "Add to Wishlist",
    "view-all-location-itineraries": "View all :location itineraries"
  },

  fields: {
    "full-name": "Full Name",
    "first-name": "First Name",
    "last-name": "Last Name",
    "current-password": "Current Password",
    "new-password": "New Password",
    password: "Password",
    email: "Email",
    message: "Message",
    search: "Search"
  },

  header: {
    destinations: "Destinations",
    profile: "Profile",
    reviews: "Reviews",
    wishlist: "Wishlist",
    logout: "Log Out",
    login: "Log In"
  },

  footer: {
    planetyze: "Planetyze",
    "private-tours": "Private Tours",
    about: "About Us",
    company: "Company",
    contact: "Contact Us",
    press: "Press",
    "follow-us": "Follow Us",
    youtube: "Youtube",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    pinterest: "Pinterest",
    legal: "Legal",
    privacy: "Privacy Policy",
    terms: "Terms of Service"
  },

  // SEO
  seo: {
    home: {
      title: "Planetyze - Japan Travel Guide, Things To Do & Itineraries",
      description:
        "Travel guide of Japan with all the necessary information you need to know before visit Japan."
    },
    location: {
      title: ":location Travel Guide - Things To Do & Itineraries",
      description: ":description"
    },
    guidebook: {
      title: ":guidebook - :location Things To Do & Itineraries",
      description: ":lead"
    },
    destinations: {
      title: "Japan Best Destinations",
      description:
        "Discover all 47 prefecture of Japan with original videos and articles on the best Japan destinations. Explore Now!"
    },
    itinerary: {
      title: ":itinerary - :location Travel Guide",
      description: ":description"
    },
    "location-internal": {
      title: ":guidebook - :location Things To Do & Itineraries",
      description: ":location :guidebook, Learn all about :location."
    },
    "location-itineraries": {
      title: ":location Day Itineraries - :location Things To Do",
      description:
        "A list of itineraries for :location made by professionals. Use these itineraries with pictures and videos to plan each day of your trip! View itineraries now!"
    },
    "location-category": {
      title: ":location :category - :location Travel Guide & Itineraries",
      description:
        "A list of the best :category in :location, with videos! View photos and get the information you need to visit your next destination. Discover :location now!"
    },
    "location-tag": {
      title: ":location :tag - :location Travel Guide & Itineraries",
      description:
        "A list of the best :tag in :location, with videos! View photos and get the information you need to visit your next destination. Discover :location now!"
    },
    "location-blogs": {
      title:
        ":location Blogs & Articles -:location Travel Guide & Itineraries ",
      description:
        "Amazing and interesting blog articles about unique parts of :location."
    },
    blog: {
      title: ":blog - :location Travel Guide & Itineraries",
      description: ":description"
    },
    category: {
      title: "Japan :category - Japan Travel Guide & Itineraries",
      description:
        "A list of the best :category in Japan, with videos! View photos and get the information you need to visit your next destination. Discover Japan now!"
    },
    tag: {
      title: "Japan :tag - Japan Travel Guide & Itineraries",
      description:
        "A list of the best :tag in Japan, with videos! View photos and get the information you need to visit your next destination. Discover Japan now!"
    },
    about: {
      title: "About Us",
      description:
        "Planetyze is the largest Japan travel guidebook, featuring videos, reviews, and articles about sightseeing spots from all over Japan."
    },
    company: {
      title: "Company",
      description: "(description) Company"
    },
    contact: {
      title: "Contact Us",
      description:
        "Planetyze is a service of Travelience Inc, phone: +81 3-6830-0896, address: Kuramae Nagatani Bldg 5F-C, 2-4-3 Kuramae, Taito-ku, Tokyo, Japan 111-0051"
    },
    thread: {
      title: ":thread - :location Travel Guide",
      description: ":description"
    },
    review: {
      title: ":review - :guidebook,:location Travel Guide",
      description: ":description"
    },
    login: {
      title: "Log In",
      description: "Organize your research & unlock tools like wishlist."
    },
    privacy: {
      title: "Privacy Policy",
      description:
        "Planetyze takes its visitors privacy very seriously. Read here the privacy policy of our service."
    },
    terms: {
      title: "Terms & Conditions",
      description:
        "The terms and conditions of using Planetyze's services. Read our terms and conditions here!"
    }
  },

  // Validations
  validations: {
    mixed: {
      default: "${path} is invalid",
      required: "${path} is a required field",
      oneOf: "${path} must be one of the following values: ${values}",
      notOneOf: "${path} must not be one of the following values: ${values}"
    },
    string: {
      length: "${path} must be exactly ${length} characters",
      min: "${path} must be at least ${min} characters",
      max: "${path} must be at most ${max} characters",
      matches: '${path} must match the following: "${regex}"',
      email: "${path} must be a valid email",
      url: "${path} must be a valid URL",
      trim: "${path} must be a trimmed string",
      lowercase: "${path} must be a lowercase string",
      uppercase: "${path} must be a upper case string"
    },
    number: {
      min: "${path} must be greater than or equal to ${min}",
      max: "${path} must be less than or equal to ${max}",
      lessThan: "${path} must be less than ${less}",
      moreThan: "${path} must be greater than ${more}",
      notEqual: "${path} must be not equal to ${notEqual}",
      positive: "${path} must be a positive number",
      negative: "${path} must be a negative number",
      integer: "${path} must be an integer"
    },
    date: {
      min: "${path} field must be later than ${min}",
      max: "${path} field must be at earlier than ${max}"
    },
    boolean: {},
    object: {
      noUnknown:
        "${path} field cannot have keys not specified in the object shape"
    },
    array: {
      min: "${path} field must have at least ${min} items",
      max: "${path} field must have less than or equal to ${max} items"
    }
  }
}
