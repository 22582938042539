import React from 'react'
import classNames from 'classnames'
import IconLocation from 'react-ionicons/lib/IosPin'
import IconGuidebook from 'react-ionicons/lib/IosEye'
import IconItinerary from 'react-ionicons/lib/IosListBox'

const Option = (props) => {
  const { item, selected, className } = props

  const classes = classNames(
    'p-3 cursor-pointer flex items-center',
    className,
    {
      'bg-blue-lightest': selected
    }
  )

  let Icon = IconLocation

  if (item.type === 'guidebook') {
    Icon = IconGuidebook
  }

  if (item.type === 'itinerary') {
    Icon = IconItinerary
  }

  return (
    <div className={classes}>
      <Icon className='fill-current text-black mr-2' fontSize='24px' />
      {item.title}
    </div>
  )
}

export default Option
