import React from 'react'
import Video from './components/Video'
import classNames from 'classnames'
import { Link } from 'libs/router'
import './Marquee.scss'

const Overlay = (props) => {
  return <div className='marquee-overlay' />
}

const OverlayFooter = (props) => {
  return <div className='marquee-overlay-footer' />
}

const Marquee = (props) => {
  const {
    title,
    subtitle,
    src,
    video,
    legend,
    children,
    className,
    url,
    centered = false,
    path = {}
  } = props

  const styles = {
    backgroundImage: `url("${src}")`
  }

  const classes = {
    container: classNames('marquee-content flex items-end', className),
    body: classNames('max-w-xl', {
      'mx-auto text-center': centered
    })
  }

  return (
    <div className='marquee' style={styles}>
      {legend && <Marquee.Legend url={url} children={legend} />}
      <div className='relative h-full'>
        <div className={classes.container}>
          <div className={classes.body}>
            {path.length > 0 && (
              <div className='flex items-center mb-4'>
                {path.map((item) => (
                  <Marquee.Path key={`path-${item.label}`} {...item} />
                ))}
              </div>
            )}
            <h1 className='text-4xl md:text-title mb-4 text-shadow leading-none '>
              {title}
            </h1>
            {subtitle && (
              <h2 className='text-lg md:text-2xl w-full font-normal'>
                {subtitle}
              </h2>
            )}
            {children && <div className='mt-4'>{children}</div>}
          </div>
        </div>
        {video && <Video src={video} />}
        <Overlay />
        <OverlayFooter />
      </div>
    </div>
  )
}

Marquee.Legend = ({ children, url }) => {
  if (!children) return null

  return (
    <Link
      to={url}
      className='bg-black pin-r pin-t absolute px-4 py-2 rounded mr-8 text-white hover:bg-primary z-30 hidden lg:block'
      style={{ top: '580px' }}
      children={children}
    />
  )
}

Marquee.Path = ({ url, label }) => {
  return (
    <Link
      to={url}
      className='mb-2 mr-4 block text-grey text-2xl flex items-center hover:text-white no-underline'>
      {label}
      <svg viewBox='0 0 18 18' className='fill-current h-2 w-2 ml-3'>
        <path
          d='m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z'
          fillRule='evenodd'
        />
      </svg>
    </Link>
  )
}

export default Marquee
