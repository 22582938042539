import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import { Spinner } from "planetyze-ui"

const LoadMore = props => {
  const {
    children,
    pageInfo = { current_page: 1, last_page: 1 },
    fetchMore,
    onChange = false
  } = props

  return (
    <InfiniteScroll
      pageStart={1}
      initialLoad={false}
      loadMore={page => {
        return fetchMore({
          variables: {
            page: pageInfo.current_page + 1
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            return onChange(prev, fetchMoreResult)
          }
        })
      }}
      hasMore={pageInfo.current_page !== pageInfo.last_page}
      loader={<Spinner key="loader" />}>
      {children}
    </InfiniteScroll>
  )
}

export default LoadMore
