import { Component } from "react"

class DropdownEvent extends Component {
  state = {
    isOpen: false
  }
  createEvent = () => {
    document.addEventListener("mousedown", this.handleClick, false)
  }

  removeEvent = () => {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  componentDidMount() {
    // this.createEvent()
  }

  componentWillUnmount() {
    this.removeEvent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (typeof document !== "undefined") {
      if (this.state.isOpen) {
        this.createEvent()
      } else {
        this.removeEvent()
      }
    }
  }

  handleClick = event => {
    if (this.node.contains(event.target)) {
      return false
    }

    this.toggleDropdown()
  }

  toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
}

export default DropdownEvent
