import React from 'react'
import { PreviewBlock } from '../index'
import classNames from 'classnames'

const PageBlock = (props) => {
  const { id, title, subtitle, children, className, preview = false, t } = props

  if (!children) {
    return null
  }

  const classes = classNames('page-block mb-16', className)

  return (
    <div id={id} className={classes}>
      <h3 className='page-block-title text-3xl font-semibold font-sans text-left mb-8'>
        {title}
      </h3>
      {subtitle && <div>{subtitle}</div>}
      {children && (
        <div>
          {preview ? <PreviewBlock children={children} t={t} /> : children}
        </div>
      )}
    </div>
  )
}

PageBlock.Slider = (props) => {
  return (
    <div className='cards-block-slider -mt-6'>
      <div className='overflow-x-auto overflow-y-hidden py-3 scrollbar-none'>
        {props.children}
      </div>
    </div>
  )
}

export default PageBlock
