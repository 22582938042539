import React from 'react'
import { Link } from 'libs/router'
import classNames from 'classnames'

const CardList = (props) => {
  const { src, title, description, url, className } = props

  const styles = { height: '120px' }

  if (src) {
    styles.backgroundImage = `url("${src}")`
  }

  const classes = classNames(
    'flex items-center mb-8 bg-white hover:bg-grey-lighter',
    className
  )

  return (
    <Link to={url} className={classes}>
      <div className='bg-cover bg-center bg-grey-dark w-64' style={styles} />
      <div className='w-full p-4'>
        <h2 className='mb-2 font-normal md:font-semibold text-2xl text-black'>
          {title}
        </h2>
        <p className='hidden md:block text-md leading-normal text-grey-darkest'>
          {description}
        </p>
      </div>
    </Link>
  )
}

export default CardList
