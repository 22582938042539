module.exports = {
  // Global
  japan: "日本",

  // Common Keywords
  common: {
    login: "登入",
    logout: "登出",
    blogs: "部落格",
    "not-found": "Page Not Found 404",
    orientation: "的定位",
    itineraries: "的行程",
    access: "到达",
    "read-more": "阅读更多",
    "show-all": "查看",
    "no-results": "此页面没有结果",
    "search-no-results": "搜索结果",
    "private-tours": "私人导赏团",
    "private-tour-guides": "私人导游"
  },

  // Pages
  home: {
    title: "日本的旅游指南",
    description: "以原创视频及文章介绍日本最好的目的地。"
  },

  destinations: {
    title: "最佳目的地"
  },

  guidebook: {
    overview: "概览",
    "photos-videos": "图片和视频",
    itineraries: "的行程",
    reviews: "评价",
    nearby: "附近景点",
    address: "地址",
    website: "网站",
    "nearby-spots": "附近景点",
    "business-hours": "营业时间",
    price: "价格",
    phone: "电话",
    about: "关于",
    access: "交通"
  },

  itinerary: {
    day: "第:number天"
  },

  itineraries: {
    title: "的行程"
  },

  blogs: {
    title: "关于 :location的部落格与文章"
  },

  about: {
    title: "关于我们",
    description:
      "Planetyze是最大的日本旅游指南，以视频，评价，文章为特色，为您介绍日本所有的观光景点。",
    lead: "您可观看视频阅读其他游客的评价来探索您感兴趣的观光景点。"
  },

  company: {
    title: "公司",
    name: {
      label: "公司名称",
      value: "Travelience Inc."
    },
    website: {
      label: "网站",
      value: "http://travelience.jp"
    },
    address: {
      label: "地址",
      value:
        "Maru-K Bldg 8F, 2-29-11 Asakusabashi, Taito-ku, Tokyo, Japan 111-0053"
    },
    phone: {
      label: "电话",
      value: "+81 (0)3-6830-0896"
    },
    established: {
      label: "成立于",
      value: "2013年2月"
    },
    ceo: {
      label: "CEO",
      value: "桥本直明"
    },
    services: {
      label: "服务",
      value: [
        "为参观访问日本的外国人提供专业导赏业务",
        "旅游业务平台",
        "地方振兴咨询业务"
      ]
    }
  },

  contact: {
    title: "联系我们",
    description: "我们能帮你什么吗？",
    success: "感谢给我们发送信息!"
  },

  auth: {
    profile: {
      title: "个人主页",
      success: "个人主页信息已更新"
    },
    password: {
      title: "密码",
      success: "密码已成功更新"
    },
    reviews: {
      title: "评价"
    },
    wishlist: {
      title: "愿望清单"
    }
  },

  login: {
    title: "登入",
    description: "组织您的研究和解锁工具，如心愿单",
    "forgot-password": "忘记密码?",
    "have-account": "已有账号？",
    signup: "登入"
  },

  // Website Blocks

  blocks: {
    destinations: "目的地",
    "popular-itineraries": "热门行程",
    "popular-category": "流行 :category",
    "blogs-articles-location": "关于 :location的部落格与文章",
    categories: "分类",
    "related-itineraries": "相关路线",
    "all-category": "所有 :category"
  },

  buttons: {
    save: "保存",
    submit: "提交",
    "play-video": "播放视频",
    back: "背部",
    close: "关",
    login: "登入",
    "view-gallery": "查看图库",
    "wishlist-remove": "从愿望清单中删除",
    "wishlist-add": "加入愿望清单",
    "view-all-location-itineraries": "查看所有:location行程"
  },

  fields: {
    "full-name": "姓名",
    "first-name": "名",
    "last-name": "姓",
    "current-password": "当前密码",
    "new-password": "新密码",
    password: "密码",
    email: "Email",
    message: "在此留言",
    search: "搜索"
  },

  header: {
    destinations: "目的地",
    profile: "个人主页",
    reviews: "评价",
    wishlist: "愿望清单",
    logout: "登出",
    login: "登入"
  },

  footer: {
    planetyze: "Planetyze",
    "private-tours": "私人导赏团",
    about: "关于我们",
    company: "公司",
    contact: "联系我们",
    press: "新闻媒体",
    "follow-us": "关注",
    youtube: "Youtube",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    pinterest: "Pinterest",
    legal: "法律",
    privacy: "隐私政策",
    terms: "服务条款"
  },

  // SEO
  seo: {
    home: {
      title: "Planetyze - 日本旅游影片指南",
      description: '"集结日本最佳旅游行程，原创影片及相关主题文章'
    },
    location: {
      title: ":location 城市旅游指南",
      description: ":description"
    },
    guidebook: {
      title: ":guidebook - :location 旅游指南",
      description: ":lead"
    },
    destinations: {
      title: "日本最棒人气旅游景点 ",
      description:
        "日本47道府县的原创影片与主题文章介绍人气目的地！！现在立即探索！"
    },
    itinerary: {
      title: ":itinerary - :location 旅游指南",
      description: ":description"
    },
    "location-internal": {
      title: ":guidebook - :location 旅游指南",
      description: ":location :guidebook."
    },
    "location-itineraries": {
      title: ":location 一日游行程 - :location 旅游指南",
      description:
        ":location 专业打造一日游行程名单。善用图文影像行程表计画你的旅游行程！立即浏览所有行程表！"
    },
    "location-category": {
      title: ":location :category - :location 旅游指南",
      description:
        ":location 最佳 :category 名单！欣赏影片，照片与相关资讯！现在即刻探索 :location!"
    },
    "location-tag": {
      title: ":location :tag - :location 旅游指南",
      description:
        ":location - 必访景点名单 :tag 。获得更多详情，影片与各种你需要的资讯！立即浏览！"
    },
    "location-blogs": {
      title: ":location 各种有趣主题文章与 -:location 旅游指南",
      description: ":location独特文化的相关趣味主题文章, 东京与京都."
    },
    blog: {
      title: ":blog - :location 旅游指南",
      description: ":description"
    },
    category: {
      title: "日本 :category - 旅游指南",
      description:
        "日本最佳 :category 名单！欣赏影片，照片与相关资讯！现在即刻探索日本!"
    },
    tag: {
      title: "日本 :tag - 旅游指南",
      description:
        "日本 - 必访景点名单 :tag 。获得更多详情，影片与各种你需要的资讯！立即浏览！"
    },
    about: {
      title: "关于我们",
      description:
        "Planetyze 是日本最大旅游指南，网罗日本所有景点原创影片、点评与观光景点主题文章！"
    },
    company: {
      title: "Company",
      description: "(description) Company"
    },
    contact: {
      title: "公司",
      description:
        "Planetyze 是由Travelience Inc.所营运。, 电话: +81 3-6830-0896, 地址: Maru-K Bldg 8F, 2-29-11 Asakusabashi, Taito-ku, Tokyo, Japan 111-0053"
    },
    thread: {
      title: ":thread - :location 旅游指南",
      description: ":description"
    },
    review: {
      title: ":review - :guidebook,:location 旅游指南",
      description: ":description"
    },
    login: {
      title: "登入 - 旅游指南",
      description: "组织您的研究和解锁工具，如心愿单。"
    },
    privacy: {
      title: "隐私权政策",
      description: "Planetyze 相当重视用户隐私。请详阅我们的隐私权政策。"
    },
    terms: {
      title: "服务条款",
      description: "服务条款适用于本服务的所有用户。请详阅我们的服务条款。"
    }
  },

  // Validations
  validations: {
    mixed: {
      default: "${path} is invalid",
      required: "${path} is a required field",
      oneOf: "${path} must be one of the following values: ${values}",
      notOneOf: "${path} must not be one of the following values: ${values}"
    },
    string: {
      length: "${path} must be exactly ${length} characters",
      min: "${path} must be at least ${min} characters",
      max: "${path} must be at most ${max} characters",
      matches: '${path} must match the following: "${regex}"',
      email: "${path} must be a valid email",
      url: "${path} must be a valid URL",
      trim: "${path} must be a trimmed string",
      lowercase: "${path} must be a lowercase string",
      uppercase: "${path} must be a upper case string"
    },
    number: {
      min: "${path} must be greater than or equal to ${min}",
      max: "${path} must be less than or equal to ${max}",
      lessThan: "${path} must be less than ${less}",
      moreThan: "${path} must be greater than ${more}",
      notEqual: "${path} must be not equal to ${notEqual}",
      positive: "${path} must be a positive number",
      negative: "${path} must be a negative number",
      integer: "${path} must be an integer"
    },
    date: {
      min: "${path} field must be later than ${min}",
      max: "${path} field must be at earlier than ${max}"
    },
    boolean: {},
    object: {
      noUnknown:
        "${path} field cannot have keys not specified in the object shape"
    },
    array: {
      min: "${path} field must have at least ${min} items",
      max: "${path} field must have less than or equal to ${max} items"
    }
  }
}
