import React from 'react'
import './Spinner.scss'
const Spinner = (props) => (
  <div className={`spinner ${props.className}`}>
    <div className='bounce1' />
    <div className='bounce2' />
    <div className='bounce3' />
  </div>
)

export default Spinner
