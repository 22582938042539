import React from "react"
import { Link } from "libs/router"
import classNames from "classnames"

const NavLink = props => {
  const { children, to, onClick, className } = props
  const classes = classNames(
    "block lg:inline-block text-white hover:underline no-underline mr-8 my-4",
    className
  )

  if (onClick && to) {
    return (
      <Link to={to} className={classes} onClick={props.onClick}>
        {children}
      </Link>
    )
  }

  if (onClick) {
    return (
      <button onClick={props.onClick} className={classes}>
        {children}
      </button>
    )
  }

  return (
    <Link to={to} className={classes}>
      {children}
    </Link>
  )
}

export default NavLink
