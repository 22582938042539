import React from 'react'
import classNames from 'classnames'
import { Link } from 'libs/router'

const Header = (props) => {
  const {
    title,
    subtitle,
    path = [],
    className,
    titleClass,
    subtitleClass
  } = props

  const styles = []
  styles.title = classNames('font-semibold', titleClass, {
    'text-4xl lg:text-5xl ': !titleClass
  })

  styles.subtitle = classNames(subtitleClass, {
    'text-xl pt-4': !subtitleClass
  })

  styles.container = classNames('my-8', className)

  return (
    <div className={styles.container}>
      {path.length > 0 && (
        <div className='flex items-center'>
          {path.map((item) => (
            <Header.Path key={`path-${item.label}`} {...item} />
          ))}
        </div>
      )}
      <h1 className={styles.title}>{title}</h1>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  )
}

Header.Path = ({ url, label }) => {
  return (
    <Link
      to={url}
      className='mb-2 mr-4 block text-grey-dark text-2xl flex items-center hover:text-primary no-underline'>
      {label}
      <svg viewBox='0 0 18 18' className='fill-current h-2 w-2 ml-3'>
        <path
          d='m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z'
          fillRule='evenodd'
        />
      </svg>
    </Link>
  )
}

export default Header
