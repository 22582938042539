module.exports = {
  // Global
  japan: "Japón",

  // Common Keywords
  common: {
    login: "Log In",
    logout: "Salir",
    blogs: "Blogs",
    "not-found": "Pagina no existe 404",
    orientation: "Orientación",
    itineraries: "Itinerarios",
    access: "Acceso",
    "read-more": "leer más",
    "show-all": "Show All",
    "no-results": "No hay resultados para esta pagina",
    "search-no-results": "No hay resultados para: ",
    "private-tours": "Tours Privados",
    "private-tour-guides": "Guías Turisticos Privados"
  },

  // Pages
  home: {
    title: "Guía de viajes y turismo en Japón",
    description: "Artículos y Videos originales de Japón"
  },

  destinations: {
    title: "Destinos"
  },

  guidebook: {
    overview: "General",
    "photos-videos": "Fotos & Videos",
    itineraries: "Itinerarios",
    reviews: "Reviews",
    nearby: "Cerca",
    address: "Dirección",
    website: "Website",
    "nearby-spots": "Lugares Cercanos",
    "business-hours": "Horario",
    price: "Precio",
    phone: "Telefono",
    about: "Acerca",
    access: "Acceso"
  },

  itinerary: {
    day: "Día :number"
  },

  itineraries: {
    title: "Itinerarios"
  },

  blogs: {
    title: "Blogs & Artículos sobre :location"
  },

  about: {
    title: "Sobre Nosotros",
    description:
      "Planetyze es la guía de viajes más grande de Japón, con videos, comentarios y artículos sobre lugares turísticos de todo Japón.",
    lead:
      "Puedes descubrir los lugares turísticos que te interesan, viendo videos cortos y leyendo comentarios escritos por otros viajeros."
  },

  company: {
    title: "Empresa",
    name: {
      label: "Empresa",
      value: "Travelience Inc."
    },
    website: {
      label: "Website",
      value: "http://travelience.jp"
    },
    address: {
      label: "Dirección",
      value:
        "Maru-K Bldg 8F, 2-29-11 Asakusabashi, Taito-ku, Tokyo, Japan 111-0053"
    },
    phone: {
      label: "Telefono",
      value: "+81 (0)3-6830-0896"
    },
    established: {
      label: "Establecida",
      value: "February 2013"
    },
    ceo: {
      label: "CEO",
      value: "Naoaki Hashimoto"
    },
    services: {
      label: "Servicios",
      value: [
        "Tour Guide Business for Foreigners Visiting japan",
        "Tourism Platform Business",
        "Regional Revitalization Consulting Business"
      ]
    }
  },

  contact: {
    title: "Contacto",
    description: "Como podes ayudar?",
    success: "Tu mensaje fue enviado!"
  },

  auth: {
    profile: {
      title: "Perfil",
      success: "Perfil Actualizado"
    },
    password: {
      title: "Contraseña",
      success: "Contraseña Actualizado"
    },
    reviews: {
      title: "Reviews"
    },
    wishlist: {
      title: "Wishlist"
    }
  },

  login: {
    title: "Log In",
    description:
      "Organiza tus herramientas de investigación y desbloqueo como lista de deseos.",
    "forgot-password": "Olvidaste tu contraseña?",
    "have-account": "No tienes cuenta?",
    signup: "Registrase"
  },

  // Website Blocks

  blocks: {
    destinations: "Destinos",
    "popular-itineraries": "Itinerarios Populares",
    "popular-category": ":category Populares",
    "blogs-articles-location": "Blogs & Artículos sobre -:location",
    categories: "Categorías",
    "related-itineraries": "Itinerarios relacionados",
    "all-category": ":category"
  },

  buttons: {
    save: "Guardar",
    submit: "Enviar",
    "play-video": "Ver Video",
    back: "Volver",
    close: "Cerrar",
    login: "Log In",
    "view-gallery": "Ver Galería",
    "wishlist-remove": "Quitar del Wishlist",
    "wishlist-add": "Agregar al Wishlist",
    "view-all-location-itineraries": "Ver todos los itineraries en :location"
  },

  fields: {
    "full-name": "Nombre Completo",
    "first-name": "Primer Nombre",
    "last-name": "Ultimo Nombre",
    "current-password": "Contraseña Actual",
    "new-password": "Nueva Contraseña",
    password: "Contraseña",
    email: "Email",
    message: "Mensaje",
    search: "Buscar"
  },

  header: {
    destinations: "Destinos",
    profile: "Perfil",
    reviews: "Reviews",
    wishlist: "Wishlist",
    logout: "Salir",
    login: "Log In"
  },

  footer: {
    planetyze: "Planetyze",
    "private-tours": "Tours Privados",
    about: "Sobre Nosotros",
    company: "Empresa",
    contact: "Contacto",
    press: "Prensa",
    "follow-us": "Seguínos",
    youtube: "Youtube",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    pinterest: "Pinterest",
    legal: "Legal",
    privacy: "Política de Privacidad",
    terms: "Términos del Servicio"
  },

  // SEO
  seo: {
    home: {
      title:
        "Planetyze - Guía de viaje de Japón, cosas que hacer e itinerarios",
      description:
        "Guía de viaje de Japón con toda la información necesaria que necesita saber antes de visitar Japón."
    },
    location: {
      title: ":location Guía de viaje - Cosas que hacer e itinerarios",
      description: ":description"
    },
    guidebook: {
      title: ":guidebook - :location Cosas que hacer e itinerarios",
      description: ":lead"
    },
    destinations: {
      title: "Mejores destinos de Japón",
      description:
        "Descubra las 47 prefecturas de Japón con videos y artículos originales sobre los mejores destinos de Japón. ¡Explora ahora!"
    },
    itinerary: {
      title: ":itinerary - :location Guía de viaje",
      description: ":description"
    },
    "location-internal": {
      title: ":guidebook - :location Cosas que hacer e itinerarios",
      description: ":location :guidebook, Aprender todo sobre :location."
    },
    "location-itineraries": {
      title: ":location Itinerarios de un Día - :location Cosas que hacer",
      description:
        "Lista de Itinerarios en :location creados por profesionales. ¡Utilice estos itinerarios con fotos y videos para planificar cada día de su viaje! Ver itinerarios ahora!"
    },
    "location-category": {
      title: ":location :category - :location Guía de viajes e itinerarios",
      description:
        "Lista de los mejores :category en :location, con videos! Vea fotos y obtenga la información que necesita para visitar su próximo destino. Descubrir :location ahora!"
    },
    "location-tag": {
      title: ":location :tag - :location Guía de viajes e itinerarios",
      description:
        "Listado de los mejores :tag en :location, con videos! Vea fotos y obtenga la información que necesita para visitar su próximo destino. Descubrir :location ahora!"
    },
    "location-blogs": {
      title:
        ":location Blogs & Artículos -:location Guía de viajes e itinerarios",
      description:
        "Increíbles e interesantes artículos sobre cosas únicas en :location."
    },
    blog: {
      title: ":blog - :location Guía de viajes e itinerarios",
      description: ":description"
    },
    category: {
      title: "Japón :category - Guía de viajes de Japón e itinerarios",
      description:
        "Lista de los mejores :category en Japón, con videos! Vea fotos y obtenga la información que necesita para visitar su próximo destino. Descubrir Japón ahora!"
    },
    tag: {
      title: "Japón :tag - Guía de viajes de Japón e itinerarios",
      description:
        "Lista de los mejores :tag en Japón, con videos! Vea fotos y obtenga la información que necesita para visitar su próximo destino. Descubrir Japón ahora!"
    },
    about: {
      title: "Sobre Nosotros",
      description:
        "Planetyze es la guía de viajes más grande de Japón, con videos, comentarios y artículos sobre lugares turísticos de todo Japón."
    },
    company: {
      title: "Empresa",
      description:
        "Dirección: Maru-K Bldg 8F, 2-29-11 Asakusabashi, Taito-ku, Tokyo, Japan 111-0053"
    },
    contact: {
      title: "Contacto",
      description:
        "Planetyze es un servicio de Travelience Inc, Telefono: +81 3-6830-0896, Dirección: Maru-K Bldg 8F, 2-29-11 Asakusabashi, Taito-ku, Tokyo, Japan 111-0053"
    },
    thread: {
      title: ":thread - :location Guía de viaje",
      description: ":description"
    },
    review: {
      title: ":review - :guidebook,:location Guia de viaje",
      description: ":description"
    },
    login: {
      title: "Log In",
      description:
        "Organiza tus herramientas de investigación y desbloqueo como Wishlist."
    },
    privacy: {
      title: "Política de Privacidad",
      description:
        "Planetyze toma muy en serio la privacidad de sus visitantes. Lea aquí la política de privacidad de nuestro servicio."
    },
    terms: {
      title: "Términos y Condiciones",
      description:
        "Los términos y condiciones de uso de los servicios de Planetyze. ¡Lea nuestros términos y condiciones aquí!"
    }
  },

  // Validations
  validations: {
    mixed: {
      default: "${path} es invalido",
      required: "${path} es un campo requerido",
      oneOf: "${path} tiene que ser uno de los siguientes valores: ${values}",
      notOneOf:
        "${path} no tiene que ser uno de los siguientes valores: ${values}"
    },
    string: {
      length: "${path} tiene que ser exacto ${length} caracteres",
      min: "${path} tiene que tener al menos ${min} caracteres",
      max: "${path} tiene que tener máximo ${max} caracteres",
      matches: '${path} tiene que coincidir con: "${regex}"',
      email: "${path} tiene que ser un email valido",
      url: "${path} tiene que ser una URL valida",
      trim: "${path} debe ser una cuerda recortada",
      lowercase: "${path} debe ser una cadena minúscula",
      uppercase: "${path} debe ser una cadena de mayúsculas"
    },
    number: {
      min: "${path} tiene que ser mayor o igual que ${min}",
      max: "${path} tiene que ser menor o igual que ${max}",
      lessThan: "${path} tiene que ser menor a ${less}",
      moreThan: "${path} tiene que ser mayor a ${more}",
      notEqual: "${path} tiene que ser distinto a ${notEqual}",
      positive: "${path} tiene que ser un numero positivo",
      negative: "${path} tiene que ser un numero negativo",
      integer: "${path} tiene que ser un entero"
    },
    date: {
      min: "${path} tiene que ser después de ${min}",
      max: "${path} tiene que ser antes de ${max}"
    },
    boolean: {},
    object: {
      noUnknown:
        "${path} no puede tener claves no especificadas en la forma del objeto"
    },
    array: {
      min: "${path} tiene que tener al menos ${min} items",
      max: "${path} tiene que tener máximo ${max} items"
    }
  }
}
