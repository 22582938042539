import React, { Component } from "react"
import { Layout } from "~/src/components"
import AppContext from "~/src/context/AppContext"

class Logout extends Component {
  componentWillMount() {
    this.context.logout()
  }

  render() {
    return (
      <Layout title="Contact Page" pageType="logout" url={this.props.uri}>
        <h1>Logout</h1>
      </Layout>
    )
  }
}

Logout.contextType = AppContext

export default Logout
