import React from 'react'
import IconSearch from 'react-ionicons/lib/IosSearch'
import classNames from 'classnames'

let onComposition = false

const Search = (props) => {
  const {
    className,
    placeholder,
    disabled,
    value,
    InputClassName,
    inputProps
  } = props

  const classes = classNames(
    'navbar-search rounded lg:rounded-none w-full flex items-center h-12 lg:h-ful',
    className
  )
  const InputClasses = classNames(
    'text-sm bg-transparent h-full w-full px-4 mr-6 text-base outline-none',
    InputClassName
  )

  const onKeyUp = (event) => {
    if (event.keyCode === 13 && onComposition) {
      onComposition = false
      return
    }
    onComposition = false
    return inputProps.onKeyDown(event)
  }

  const onCompositionEnd = (event) => (onComposition = true)

  return (
    <div className={classes}>
      <IconSearch className='ml-2 text-lg fill-current' />
      <input
        placeholder={placeholder}
        className={InputClasses}
        disabled={disabled}
        value={value}
        onBlur={inputProps.onBlur}
        onFocus={inputProps.onFocus}
        onCompositionEnd={onCompositionEnd}
        onKeyUp={onKeyUp}
        onClick={inputProps.onClick}
        onChange={inputProps.onChange}
        autoComplete={inputProps.autoComplete}
        ref={inputProps.ref}
      />
    </div>
  )
}

Search.defaultProps = {
  placeholder: 'Search...'
}

export default Search
