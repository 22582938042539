import React from "react"
import { Router, LocationProvider } from "@reach/router"
import loadable from "@loadable/component"
import { logPageView } from "./libs/analytics"
import Logout from "./pages/Logout/Logout"
import { Layout } from "./components"

const NotFound = loadable(() => import("pages/NotFound"))
const Home = loadable(() => import("pages/Home"))
const Destinations = loadable(() => import("pages/Destinations"))
const Itinerary = loadable(() => import("pages/Itinerary"))
const Guidebook = loadable(() => import("pages/Guidebook"))
const Location = loadable(() => import("pages/Location"))
const LocationCategory = loadable(() => import("pages/Location/Category"))
const LocationCategoryTag = loadable(() =>
  import("pages/Location/Category/Tag")
)
const LocationItineraries = loadable(() => import("pages/Location/Itineraries"))
const LocationBlogs = loadable(() => import("pages/Location/Blogs"))
const GuidebookInternal = loadable(() =>
  import("pages/Guidebook/Internal/Internal")
)
const Blog = loadable(() => import("pages/Blog"))
const Category = loadable(() => import("pages/Category"))
const CategoryTag = loadable(() => import("pages/Category/Tag"))
const Login = loadable(() => import("pages/Login"))
const Privacy = loadable(() => import("pages/Privacy"))
const Terms = loadable(() => import("pages/Terms"))
const About = loadable(() => import("pages/About"))
const Company = loadable(() => import("pages/Company"))
const AuthProfile = loadable(() => import("pages/Auth/Profile"))
const AuthPassword = loadable(() => import("pages/Auth/Password"))
const AuthWishlist = loadable(() => import("pages/Auth/Wishlist"))
const AuthReviews = loadable(() => import("pages/Auth/Reviews"))
const Review = loadable(() => import("pages/Review"))
const Contact = loadable(() => import("pages/Contact"))
const Thread = loadable(() => import("pages/Thread"))

const Loading = (
  <div className="container p-4">
    <Layout.Spinner />
  </div>
)

const Routes = () => (
  <React.Fragment>
    <LocationProvider>
      {context => {
        if (typeof window !== "undefined" && !context.location.hash) {
          logPageView(context.location.pathname)
          setTimeout(() => window.scrollTo(0, 0), 100)
        }
      }}
    </LocationProvider>

    <Router basepath=":lang">
      <NotFound default fallback={Loading} />
      <Home path="/" fallback={Loading} />
      <Destinations path="/japan/destinations" fallback={Loading} />
      <Itinerary path="/japan/:slug/itinerary/:itinerary" fallback={Loading} />
      <Guidebook path="/japan/:slug/:guidebook" fallback={Loading} />
      <GuidebookInternal path="/japan/:slug/orientation" fallback={Loading} />
      <GuidebookInternal path="/japan/:slug/videos" fallback={Loading} />
      <GuidebookInternal path="/japan/:slug/access" fallback={Loading} />
      <Category path="/japan/category/:category" fallback={Loading} />
      <CategoryTag path="/japan/category/:category/:tag" fallback={Loading} />
      <Location path="/japan/:slug" fallback={Loading} />
      <LocationCategory
        path="/japan/:slug/category/:category"
        fallback={Loading}
      />
      <LocationCategoryTag
        path="/japan/:slug/category/:category/:tag"
        fallback={Loading}
      />
      <LocationItineraries path="/japan/:slug/itineraries" fallback={Loading} />
      <Blog path="/japan/:slug/blog/:blog" fallback={Loading} />
      <Blog path="/japan/blog/:blog" fallback={Loading} />
      <LocationBlogs path="/japan/:slug/blogs" fallback={Loading} />
      <Login path="/login" fallback={Loading} />
      <Logout path="/logout" fallback={Loading} />
      <Privacy path="/privacy" fallback={Loading} />
      <Terms path="/terms" fallback={Loading} />
      <About path="/about" fallback={Loading} />
      <Company path="/company" fallback={Loading} />
      <AuthProfile path="/auth/profile" fallback={Loading} />
      <AuthPassword path="/auth/password" fallback={Loading} />
      <AuthReviews path="/auth/reviews" fallback={Loading} />
      <AuthWishlist path="/auth/wishlist" fallback={Loading} />
      <Review path="/review/:review" fallback={Loading} />
      <Contact path="/contact" fallback={Loading} />
      <Thread
        path="/japan/forum/:slug/travel-tips/:thread"
        fallback={Loading}
      />
    </Router>
  </React.Fragment>
)

export default Routes
