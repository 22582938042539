import React from 'react'
import trans from '../libs/translations'
import Cookies from 'universal-cookie'
// import { navigate } from 'libs/router'

let initialState = {}

if (typeof window !== 'undefined') {
  initialState = window.__STATE__.initialState
}

const AppContext = React.createContext(initialState)

class AppProvider extends React.Component {
  constructor (props) {
    super(props)
    this.state = props.state || initialState
  }

  update = (user) => {
    delete user['token']

    this.setState({ auth: user })

    return true
  }

  login = (user) => {
    const cookies = new Cookies()

    cookies.set('token', user.token, { path: '/' })
    delete user['token']

    this.setState({ isAuth: true, auth: user }, () => {
      window.location = '/' + this.state.lang
    })
  }

  logout = () => {
    const cookies = new Cookies()
    cookies.remove('token', { path: '/' })
    this.setState({ isAuth: false, user: null }, () => {
      window.location = '/' + this.state.lang
    })
  }

  translate = (key, params = {}, lang) => {
    return trans(key, params, lang || this.state.lang)
  }

  changeLanguage = (lang) => {
    const path = window.location.pathname.replace('/' + this.state.lang, '')
    window.location.href = `/${lang}${path}`
  }

  render () {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          login: this.login,
          update: this.update,
          logout: this.logout,
          changeLanguage: this.changeLanguage,
          t: this.translate
        }}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

const AppConsumer = AppContext.Consumer

export default AppContext
export { AppProvider, AppConsumer }
