import React from 'react'
import classNames from 'classnames'
import { Link } from 'libs/router'

const Category = (props) => {
  const { url, name, className } = props

  const classes = classNames(
    'px-8 py-2 lg:py-4 text-md lg:text-lg border border-primary text-primary rounded-full mr-2 mb-2 flex whitespace-no-wrap hover:text-white hover:bg-primary-light',
    className
  )

  return (
    <Link to={url} className={classes}>
      <div className='w-full text-center'>{name}</div>
    </Link>
  )
}

export default Category
