import React from 'react'
import { Link } from 'libs/router'
import { PageBlock, Card } from '../index'
import classNames from 'classnames'
import './CardsBlock.scss'

const CardsBlock = (props) => {
  const {
    title,
    subtitle,
    items,
    pageInfo,
    url,
    children,
    slider,
    t,
    src,
    theme = false
  } = props

  if (!children && (!items || items.length === 0)) {
    return null
  }

  let cards = null

  if (items) {
    cards = (
      <CardsBlock.Items items={items} slider={slider} src={src} theme={theme} />
    )
  }

  return (
    <PageBlock title={title} subtitle={subtitle}>
      {slider && items && <PageBlock.Slider children={cards} />}
      {!slider && cards}
      {children && <div>{children}</div>}
      {url && (
        <CardsBlock.Link url={url} pageInfo={pageInfo} slider={slider} t={t} />
      )}
    </PageBlock>
  )
}

CardsBlock.Items = ({ items, slider, theme, fetchMore, src }) => {
  const CardClass = classNames('', {
    'w-card min-w-card -ml-2 p-2': slider,
    'w-full sm:w-1/2 md:w-1/3 lg:w-1/4 lg:pb-12 lg:pr-2 xxl:w-1/5 pr-2 pb-4': !slider,
    'card-lg': theme === 'lg'
  })

  const classes = classNames('flex items-center cards-block', {
    'flex-wrap page-block': !slider
  })

  return (
    <div className={classes}>
      {items.map((item, key) => (
        <div
          key={item.id}
          className={classNames(`card-item card-item-${item.id}`, CardClass)}>
          <Link to={item.url} className='no-underline'>
            <Card
              src={item.image ? item.image.url : src}
              title={item.title}
              description={item.description}
              location={item.location ? item.location.name : null}
              url={item.url}
            />
          </Link>
        </div>
      ))}
    </div>
  )
}

CardsBlock.Link = ({ url, pageInfo, slider, t }) => {
  const classes = classNames(
    'justify-center border border-primary py-3 rounded',
    'md:rounded-none md:border-0 md:py-0 md:justify-start',
    'text-primary no-underline md:hover:underline flex items-center',
    {
      '-mt-4': !slider,
      'mt-2': slider
    }
  )
  return (
    <Link to={url} className={classes}>
      <div className='mr-3'> {getPageInfoText(pageInfo, t)} </div>
      <svg viewBox='0 0 18 18' className='fill-current h-2 w-2'>
        <path
          d='m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z'
          fillRule='evenodd'
        />
      </svg>
    </Link>
  )
}

const getPageInfoText = (pageInfo, t) => {
  let text = t('common.show-all')

  if (!pageInfo) {
    return text
  }

  const { total } = pageInfo

  if (total > 10) {
    const divisor = total > 100 ? 100 : 10
    const items = (Math.round(total / divisor) * divisor).toLocaleString()
    text = `${t('common.show-all')} (${items}+)`
  }

  return text
}

CardsBlock.defaultProps = {
  t: (text) => text
}

export default CardsBlock
