/*eslint-disable*/
import React from 'react'
import { Link as BaseLink, navigate as _navigate } from '@reach/router'
import { AppConsumer } from '../context/AppContext'

const cleanRoute = (route) => {
  if (route.endsWith('/')) {
    return route.slice(0, -1)
  }

  return route
}

export const Link = ({ to, ...props }) => {
  if (to.includes('http')) {
    return (
      <a href={to} target='_blank' {...props} rel='opener referrer'>
        {props.children}
      </a>
    )
  }

  return (
    <AppConsumer>
      {({ lang }) => {
        const route = cleanRoute('/' + lang + to)
        return (
          <BaseLink to={route} {...props}>
            {props.children}
          </BaseLink>
        )
      }}
    </AppConsumer>
  )
}

export const navigate = (to) => {
  if (to.includes('http')) {
    window.location.href = to
    return true
  }

  const context = AppConsumer._currentValue
  const route = cleanRoute('/' + context.lang + to)

  _navigate(route)
}
