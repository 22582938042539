import React, { Component } from 'react'
import { Spinner } from '../../index'

const Loading = ({ children }) => (
  <div className='text-center text-xl py-16'>
    <Spinner />
  </div>
)

const Error = (props) => (
  <div className='container m-24 text-center mx-auto text-2xl'>
    {props.children}
  </div>
)

class Loader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: props.error
    }
  }

  componentDidCatch (error, info) {
    this.setState({ error })
  }

  render () {
    const { loading, children } = this.props
    const { error } = this.state

    if (loading) return <Loading />
    if (error) return <Error children={error} />

    return children(this.props)
  }
}

export default Loader
