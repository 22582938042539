import React from 'react'
import { hydrate } from 'react-dom'
import { initGA } from '../libs/analytics'
import { loadableReady } from '@loadable/component'
import App from '../_app'
import initApollo from '../libs/apollo'

const root = document.getElementById('root')
const state = window.__STATE__
const apollo = initApollo({
  state: state.apolloState,
  lang: state.initialState.lang
})

loadableReady(() => {
  initGA()
  hydrate(<App apollo={apollo} state={state.initialState} />, root)
})

if (module.hot) {
  module.hot.accept()
}
