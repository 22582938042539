import React from "react"
import getUTM from "../../utils/getUTM"
import getContext from "context"


const Wrapper = ({className, children}) => <div className={`text-center ${className}`}>{children}</div>

const Ads = ({variant, medium, campaign, className}) => {

    const { lang } = getContext();

    if(lang !== "ja")
    {
        return null;
    }

    const params = getUTM(
        "planetyze",
        medium,
        campaign
      );
    const url = `https://english.learnwithcoach.com/` + params;

    if(variant === "default")
    {
        return (<Wrapper className={className}><a href={url} target="_blank"><img src="/ads/lwc-en-ad.jpg" /></a></Wrapper>);
    }
    
    return (<Wrapper className={className}><a href={url} target="_blank"><img src="/ads/lwc-en-ad-wide.png" /></a></Wrapper>);
}


export default Ads;
