import React from "react";

export default class Ads extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    const { variant = "body", className = "" } = this.props;
    let settings = {};

    if (variant === "header") {
      settings = {
        "data-ad-slot": "1622291404"
      };
    }

    if (variant === "body") {
      settings = {
        "data-ad-slot": "7612984686",
        "data-ad-format": "auto",
        "data-full-width-responsive": "true"
      };
    }

    if (variant === "footer") {
      settings = {
        "data-ad-slot": "5262153519",
        "data-ad-format": "autorelaxed"
      };
    }

    return (
      <div className={className}>
        <div className="container-center">
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-3338462769937042"
            {...settings}
          />
        </div>
      </div>
    );
  }
}
