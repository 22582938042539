import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { inViewPort } from '../helpers'
import './Card.scss'

const Card = (props) => {
  const { title, description, src, className, location } = props
  const styles = { height: '170px' }
  const [
    state,
    setState
  ] = useState({ src: false })
  const ref = useRef(null)

  const imageLoad = (e = false) => {
    if (inViewPort(ref.current)) {
      setState({ src })
    }
  }

  useEffect(
    () => {
      imageLoad()
      if (state.src) return false
      window.addEventListener('scroll', imageLoad)
      return () => window.removeEventListener('mousemove', imageLoad)
    },
    [
      false
    ]
  )

  if (state.src) {
    styles.backgroundImage = `url("${state.src}")`
  }

  const classes = classNames('card-container', className)

  return (
    <div className={classes} ref={ref}>
      <div className='card-picture' style={styles}>
        <div className='card-inner-shadow w-full'>
          {location && <div className='card-tag'>{location}</div>}
          <div className='card-title text-shadow'>{title}</div>
          {description && <div className='card-description'>{description}</div>}
        </div>
      </div>
    </div>
  )
}

export default Card
