import React from 'react'
import { Link } from 'libs/router'
import classNames from 'classnames'
import Scrollspy from 'react-scrollspy'

const Menu = (props) => {
  const { items = {}, className, current = false, scroll = false } = props

  if (items.length < 3) {
    return null
  }

  let selected = current

  if (!selected) {
    selected = items[0].url
  }

  const classes = classNames(
    'lg:flex items-center mb-8 mx-0 overflow-x-auto',
    className,
    {
      'justify-between': items.length > 3,
      'justify-center': items.length <= 3
    }
  )

  const links = []

  Object.keys(items).forEach((key) => {
    const item = items[key]
    const classes = classNames(
      'text-grey-darkest text-base px-6 py-2 rounded-full whitespace-no-wrap',
      {
        'bg-grey-light hover:underline': selected === item.url,
        'bg-white hover:underline': selected !== item.url
      }
    )

    if (scroll) {
      links.push(
        <a key={`menu-${key}`} href={item.url} className={classes}>
          {item.label}
        </a>
      )
    }    else {
      links.push(
        <Link key={`menu-${key}`} to={item.url} className={classes}>
          {item.label}
        </Link>
      )
    }
  })

  if (scroll) {
    return (
      <Scrollspy
        items={items.map((item) => item.url.replace('#', ''))}
        offset={550}
        componentTag='div'
        className={classes}
        currentClassName='guidebook-menu-selected'>
        {links}
      </Scrollspy>
    )
  }

  return <div className={classes}>{!scroll && links}</div>
}
export default Menu
