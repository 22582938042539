import React, { Component } from "react"
import AppContext from "~/src/context/AppContext"
import { NavLink, Logo, Dropdown } from "./components"
import { Search } from "planetyze-ui"
import IconMenu from "react-ionicons/lib/IosMenu"
import IconSearch from "react-ionicons/lib/IosSearch"
import IconClose from "react-ionicons/lib/MdCloseCircle"
import classNames from "classnames"
import { ApolloConsumer } from "react-apollo"
import { navigate } from "../../libs/router"
import SEARCH_QUERY from "./Search.gql"
import { Location } from "@reach/router"
import getUTM from "../../utils/getUTM"
import { removeLang } from "../../libs/translations"
import "./Navbar.scss"

const getTripleLightsLocationLinks = path => {
  let match = false

  if (path.includes("forum") && !path.includes("forum/japan")) {
    match = path.match(/forum\/(\w|-)*/g)
  } else if (!path.includes("forum")) {
    match = path.match(/japan\/(\w|-)*/g)
  }

  if (!match) {
    return {
      guides: "https://triplelights.com/japan/guides",
      tours: "https://triplelights.com"
    }
  }

  const location = match[0]
    .replace("japan/", "")
    .replace("forum/japan", "")
    .replace("forum/", "")

  return {
    guides: `https://triplelights.com/japan/${location}/guides`,
    tours: `https://triplelights.com/japan/${location}/tours`
  }
}

class Navbar extends Component {
  state = {
    isOpen: false,
    isSearchOpen: false
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
  }

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  toogleSearch = () => {
    this.setState({ isOpen: false, isSearchOpen: !this.state.isSearchOpen })
  }

  onSearch = async (apollo, value) => {
    const { data, loading } = await apollo.query({
      query: SEARCH_QUERY,
      variables: { query: value },
      fetchPolicy: "no-cache"
    })

    if (!loading) {
      if (data.search.nodes.length > 0) {
        return data.search.nodes
      }
    }

    return []
  }

  onSearchChange = item => {
    this.setState({ search: item.title, isSearchOpen: false })
    navigate(item.url)
  }

  render() {
    const { isAuth, logout, t, auth } = this.context
    const { isOpen, isSearchOpen } = this.state

    const classes = classNames("lg:block my-4 lg:my-0 mt-8 lg:mt-0", {
      hidden: !isOpen
    })

    const SearchInput = () => {
      return (
        <ApolloConsumer>
          {client => {
            const items = []
            return (
              <Search
                items={items}
                placeholder={t("fields.search")}
                empty={t("common.search-no-results")}
                onChange={this.onSearchChange}
                onSearch={value => this.onSearch(client, value)}
              />
            )
          }}
        </ApolloConsumer>
      )
    }

    return (
      <Location>
        {({ location }) => {
          const TripleLights = getTripleLightsLocationLinks(location.pathname)

          return (
            <nav className="navbar py-4 lg:py-0">
              <div className="md:hidden text-white">
                {isSearchOpen ? (
                  <IconClose
                    className="text-lg fill-current cursor-pointer hover:opacity-75"
                    fontSize="28px"
                    onClick={this.toogleSearch}
                  />
                ) : (
                  <IconSearch
                    className="text-lg fill-current cursor-pointer hover:opacity-75"
                    fontSize="28px"
                    onClick={this.toogleSearch}
                  />
                )}
              </div>
              <NavbarLogo />
              <NavbarMenu onClick={this.toggleMenu} />

              {isSearchOpen && (
                <div className="md:hidden bg-primary-dark rounded mt-4 w-full">
                  <SearchInput />
                </div>
              )}

              <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                <div className="hidden lg:block lg:flex-grow">
                  <SearchInput />
                </div>
                <div className={classes}>
                  <NavLink
                    to={`${TripleLights.guides}${getUTM(
                      "planetyze",
                      "navbar-private_tour_guides",
                      removeLang(location.pathname)
                    )}`}
                    children={t("common.private-tour-guides")}
                  />
                  <NavLink
                    to={`${TripleLights.tours}${getUTM(
                      "planetyze",
                      "navbar-private_tours",
                      removeLang(location.pathname)
                    )}`}
                    children={t("common.private-tours")}
                  />
                  <NavLink
                    to="/japan/destinations"
                    children={t("header.destinations")}
                    onClick={this.closeMenu}
                  />
                  {!isAuth && (
                    <NavLink
                      to="/login"
                      children={t("header.login")}
                      onClick={this.closeMenu}
                      className="navbar-button"
                    />
                  )}
                  {/* {isAuth && <NavLink to='/logout' children='Logout Route' />} */}
                  {isAuth && (
                    <Dropdown
                      className="mr-4"
                      items={[
                        {
                          text: t("header.profile"),
                          to: "/auth/profile",
                          onClick: this.closeMenu
                        },
                        {
                          text: t("header.reviews"),
                          to: "/auth/reviews",
                          onClick: this.closeMenu
                        },
                        {
                          text: t("header.wishlist"),
                          to: "/auth/wishlist",
                          onClick: this.closeMenu
                        },
                        { text: t("header.logout"), onClick: logout }
                      ]}>
                      {auth.first_name}
                    </Dropdown>
                  )}
                </div>
              </div>
            </nav>
          )
        }}
      </Location>
    )
  }
}

Navbar.contextType = AppContext

const NavbarLogo = props => (
  <div className="flex items-center flex-no-shrink text-white mr-6 h-8">
    <span className="font-semibold text-xl tracking-tight">
      <Logo />
    </span>
  </div>
)

const NavbarMenu = props => (
  <div className="block lg:hidden">
    <IconMenu
      className="fill-current text-white cursor-pointer hover:opacity-75"
      fontSize="32px"
      onClick={props.onClick}
    />
  </div>
)

export default Navbar
