import React, { Fragment } from 'react'
import classNames from 'classnames'

const Button = ({
  children,
  type = 'button',
  error,
  className,
  isSubmitting,
  theme,
  size,
  ...props
}) => {
  const themeStyles = `bg-${theme} hover:bg-${theme}-dark text-white`
  const classes = classNames('cursor-pointer rounded', className, themeStyles, {
    'px-6 py-3 text-base': size === 'default',
    'px-6 py-4 text-lg': size === 'lg',
    'text-center opacity-75': isSubmitting
  })

  if (isSubmitting) {
    return <div className={classes}>Submitting...</div>
  }

  return (
    <Fragment>
      <button type={type} className={classes} children={children} {...props} />
      {error && <p className='text-red text-xs italic mt-2'>{error}</p>}
    </Fragment>
  )
}

Button.defaultProps = {
  theme: 'primary',
  size: 'default'
}

export default Button
