import React from 'react'
import { Helmet } from 'react-helmet'

const Head = (props) => {
  const { title, description } = props
  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name='description' content={description} />}
    </Helmet>
  )
}

export default Head
