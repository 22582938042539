import React, { Component } from 'react'
import { Link } from 'libs/router'
import { Select } from 'planetyze-ui'
import AppContext from '~/src/context/AppContext'
import config from '~/config/app'

const NavLink = (props) => {
  const { to, href, children } = props

  if (href) {
    return (
      <a
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className='block w-full mb-3 text-blue-dark hover:underline'>
        {children}
      </a>
    )
  }

  return (
    <Link to={to} className='block w-full mb-3 text-blue-dark hover:underline'>
      {children}
    </Link>
  )
}

const NavTitle = (props) => {
  const { children } = props
  return <div className='block w-full text-dark text-lg mb-6'>{children}</div>
}

class Footer extends Component {
  handleLanguage = (event) => {
    const { changeLanguage } = this.context
    changeLanguage(event.target.value)
  }
  render () {
    const languages = []
    Object.keys(config.locales).map((key) =>
      languages.push({
        id: key,
        value: key,
        children: config.locales[key]
      })
    )

    const { lang, t } = this.context

    return (
      <div className='border-t border-grey-light p-8'>
        <div className='w-full flex flex-wrap justify-between container-center mx-auto'>
          <div className='w-full xl:w-1/4 mb-8 lg:pr-8'>
            <Select
              items={languages}
              value={lang}
              onChange={this.handleLanguage}
            />
          </div>
          <div className='w-full md:w-1/4 mb-8'>
            <NavTitle children={t('footer.planetyze')} />
            <NavLink
              href='https://triplelights.com/?utm_source=planetyze'
              children={t('footer.private-tours')}
            />
            <NavLink to='/about' children={t('footer.about')} />
            <NavLink to='/company' children={t('footer.company')} />
            <NavLink to='/contact' children={t('footer.contact')} />
            <NavLink
              href='https://travelience.jp/en/category/press/planetyze-press/'
              children={t('footer.press')}
            />
          </div>
          <div className='w-full md:w-1/4 mb-8'>
            <NavTitle children={t('footer.follow-us')} />
            <NavLink
              href='https://www.youtube.com/channel/UCzWCxvzei2ENMmjgSPb3-3Q'
              children={t('footer.youtube')}
            />
            <NavLink
              href='https://www.facebook.com/planetyze/'
              children={t('footer.facebook')}
            />
            <NavLink
              href='https://twitter.com/planetyze'
              children={t('footer.twitter')}
            />
            <NavLink
              href='https://www.instagram.com/planetyzeofficial/'
              children={t('footer.instagram')}
            />
            <NavLink
              href='https://www.pinterest.jp/planetyze/'
              children={t('footer.pinterest')}
            />
          </div>
          <div className='w-full md:w-64 mb-8'>
            <NavTitle children={t('footer.legal')} />
            <NavLink to='/privacy' children={t('footer.privacy')} />
            <NavLink to='/terms' children={t('footer.terms')} />
          </div>
        </div>
      </div>
    )
  }
}

Footer.contextType = AppContext

export default Footer
