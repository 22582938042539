import React from 'react'
import ModalEvent from './ModalEvent'
import IconClose from 'react-ionicons/lib/MdCloseCircle'
import classNames from 'classnames'

class Modal extends ModalEvent {
  render () {
    const { children, isOpen, right, left, t } = this.props
    let theme = 'center'
    if (right) {
      theme = 'right'
    }
    if (left) {
      theme = 'left'
    }

    if (!isOpen) {
      return null
    }

    const styles = classNames('max-w-lg', {
      'mx-auto': theme === 'center',
      'pin-r max-w-1/2': theme === 'right',
      'pin-l max-w-1/2': theme === 'left'
    })

    return (
      <div className='bg-transparent w-full h-screen fixed pin-t pin-l z-50'>
        <div ref={(node) => (this.node = node)} className={styles}>
          {theme === 'center' && (
            <Modal.Center
              onClose={this.handleClose}
              children={children}
              t={t}
            />
          )}
          {theme === 'right' && (
            <Modal.Right onClose={this.handleClose} children={children} t={t} />
          )}
        </div>
      </div>
    )
  }
}

Modal.Right = (props) => {
  const { children, onClose, t } = props

  return (
    <div className='bg-white p-2 md:p-8 max-w-md xl:w-1/2 fixed pin-t pin-r h-screen shadow-lg z-50 overflow-y-auto'>
      <IconClose
        fontSize='24px'
        className='fill-current hidden md:block absolute pin-t pin-r m-2 cursor-pointer hover:text-primary text-black'
        onClick={onClose}
      />
      <div className='mt-8 md:mt-2 block'>{children}</div>

      <div className='w-full bg-white fixed pin-b pin-l p-4'>
        <div
          className='md:hidden bg-grey-light text-grey-black rounded p-4 text-center w-full cursor-pointer hover:bg-grey'
          onClick={onClose}>
          {t('buttons.close')}
        </div>
      </div>
    </div>
  )
}

Modal.Center = (props) => {
  const { children, onClose } = props
  return (
    <div className='bg-white p-8 w-full max-w-lg mx-auto shadow-lg md:mt-32 overflow-y-auto md:rounded relative'>
      <IconClose
        fontSize='24px'
        className='fill-current absolute pin-t pin-r m-4 cursor-pointer hover:text-primary text-black'
        onClick={onClose}
      />
      {children}
    </div>
  )
}

Modal.defaultProps = {
  isOpen: false,
  centered: true,
  right: false,
  left: false,
  t: (t) => t
}

export default Modal
