import React, { useState } from 'react'
import classNames from 'classnames'

const PreviewBlock = (props) => {
  const [
    isOpen,
    setOpen
  ] = useState(false)
  const { children, className, height, t } = props

  if (isOpen) {
    return <div className={className} children={children} />
  }

  const classes = classNames('overflow-hidden', className)

  return (
    <div>
      <div
        className={classes}
        style={{ maxHeight: height }}
        children={children}
      />
      <div
        onClick={() => setOpen(!isOpen)}
        className='text-primary py-4 text-left w-full underline cursor-pointer inline-block'
        style={{
          boxShadow: '0px -3px 63px 40px #fff'
        }}>
        {t('common.read-more')}
      </div>
    </div>
  )
}

PreviewBlock.defaultProps = {
  height: '320px',
  t: (t) => t
}

export default PreviewBlock
