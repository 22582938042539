export const inViewPort = (el) => {
  if (!el) {
    return false
  }

  var top = el.offsetTop
  //   var left = el.offsetLeft
  //   var width = el.offsetWidth
  //   var height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
    // left += el.offsetLeft
  }

  return top < window.innerHeight + window.pageYOffset
  //   return (
  //     top >= window.pageYOffset &&
  //     left >= window.pageXOffset &&
  //     top + height <= window.pageYOffset + window.innerHeight
  //     // && left + width <= window.pageXOffset + window.innerWidth
  //   )
}

export const ieVersion = () => {
  const ua = window.navigator.userAgent
  if (ua.indexOf('Trident/7.0') > -1) {return 11}
  else if (ua.indexOf('Trident/6.0') > -1) {return 10}
  else if (ua.indexOf('Trident/5.0') > -1) {return 9}
  else {return 0} // not IE9, 10 or 11
}
