import React, { Component } from 'react'
import ReactAutocomplete from 'react-autocomplete'
import classNames from 'classnames'
import InputSearch from './components/InputSearch'
import Option from './components/Option'
import './Search.scss'

class Search extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value,
      items: props.items || [],
      loading: false,
      isOpen: props.isOpen || false
    }
  }

  componentDidMount () {
    this.input.focus()
  }

  onSelect = (value, item) => {
    if ('onChange' in this.props) {
      this.setState({ value: item.title })
      this.props.onChange(item)
    }
  }

  handleChange = async (value) => {
    if ('onSearch' in this.props) {
      // this.setState({ loading: true })
      const items = await this.props.onSearch(value)

      if (items) {
        this.setState({ items, value }) //  loading: false
      }
    }
  }

  handleFilter = (item, value) => {
    return item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
  }

  renderMenu = (items, value, style) => {
    let results = items
    const classes = classNames(
      'dropdown-box shadow border py-2 border-grey-light absolute bg-white z-30 rounded-none w-full max-w-md',
      {
        'input-search-menu': this.state.isOpen,
        hidden: value === ''
      }
    )

    if (this.state.loading) {
      results = <div className='p-2' children='Loading...' />
    }

    if (value !== '' && items.length === 0) {
      results = (
        <div className='p-2'>
          {this.props.empty}
          <div className='text-black font-bold inline-block ml-1'>
            “{value.trim()}”
          </div>
        </div>
      )
    }

    return <div style={{ ...style }} className={classes} children={results} />
  }

  render () {
    const { placeholder } = this.props
    const { value, items, isOpen } = this.state
    const classes = classNames('input-search', {
      'input-search--focus': isOpen
    })

    return (
      <div className='input-autocomplete'>
        <ReactAutocomplete
          shouldItemRender={this.handleFilter}
          renderMenu={this.renderMenu}
          getItemValue={(item) => item.title}
          onMenuVisibilityChange={(isOpen) => {
            this.setState({ isOpen })
          }}
          open={isOpen}
          items={items}
          ref={(el) => (this.input = el)}
          renderItem={(item, selected) => (
            <div key={`${item.type}-${item.id}`}>
              <Option item={item} selected={selected} />
            </div>
          )}
          renderInput={(props) => {
            return (
              <InputSearch
                placeholder={placeholder}
                inputProps={props}
                className={classes}
              />
            )
          }}
          value={value}
          onChange={(e) => this.handleChange(e.target.value)}
          onSelect={this.onSelect}
        />
      </div>
    )
  }
}

Search.defaultProps = {
  placeholder: 'Search',
  empty: 'No results for'
}

export default Search
