import React from 'react'
import classNames from 'classnames'

const Video = (props) => {
  const { code, height, className } = props
  const src = `https://www.youtube.com/embed/${code}`
  const styles = classNames('w-full max-h-64 md:max-h-full', className)

  return (
    <div className='max-w-xl'>
      <iframe
        src={src}
        title='Video'
        frameBorder='0'
        height={height}
        className={styles}
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>
  )
}

Video.defaultProps = {
  height: 420
}

export default Video
