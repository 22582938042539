import React from 'react'
import classNames from 'classnames'

const Input = ({ type = 'text', error, ...props }) => {
  const classes = classNames('form-control', {
    'form-control-error': error
  })

  return (
    <div className='mb-4'>
      <input type={type} className={classes} {...props} />
      {error && <p className='text-red text-xs italic mt-2'>{error}</p>}
    </div>
  )
}

export default Input
