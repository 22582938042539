import 'url-search-params-polyfill';

const getUTM = (source = "planetyze", medium, campaign) => {
  const params = new URLSearchParams()

  if (source) {
    params.append("utm_source", source)
  }

  if (medium) {
    params.append("utm_medium", medium)
  }

  if (campaign) {
    params.append("utm_campaign", encodeURI(campaign))
  }

  return "?" + params
}

export default getUTM
