import React from 'react'

const Avatar = (props) => {
  const { label, src } = props

  const styles = {}

  if (src) {
    styles.backgroundImage = `url("${src}")`
  }

  return (
    <div className='flex flex-wrap justify-center'>
      <div
        style={styles}
        className='bg-cover bg-center w-24 h-24 bg-grey-dark rounded-full'
      />
      {label && <div className='text-base mt-3' children={label} />}
    </div>
  )
}

export default Avatar
