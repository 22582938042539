import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import "isomorphic-unfetch"
import Cookies from "universal-cookie"
import config from "~/config/app"

const initApollo = ({ headers, state = {}, lang = false }) => {
  let cookies = new Cookies()
  if (headers) {
    cookies = new Cookies(headers.cookie)
  }

  const params = {
    Authorization: cookies.get("token"),
    lang: lang
  }

  return new ApolloClient({
    link: new HttpLink({
      uri: config.graphql,
      headers: params
    }),
    cache: new InMemoryCache().restore(state),
    ssrMode: true,
    ssrForceFetchDelay: 100,
    headers: params
  })
}

export default initApollo
