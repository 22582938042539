import React from 'react'
import { Link } from 'libs/router'

const Logo = (props) => {
  return (
    <Link to='/'>
      <img src='/img/logo.svg' alt='Planetyze' className='navbar-logo' />
    </Link>
  )
}

export default Logo
