import locales from "~/config/locales"
import config from "~/config/app"
import { join } from "path"

export const removeLang = path => {
  const urlPaths = path.split("/")
  urlPaths.shift()
  urlPaths.shift()
  return "/" + urlPaths.join("/")
}

export const hasLang = lang => {
  if (lang === "zh-tw") {
    return "zh-hant"
  }

  if (lang === "zh-cn") {
    return "zh-hans"
  }

  if (lang in locales) {
    return lang
  }

  if (lang.includes("zh")) {
    return "zh-hans"
  }

  return false
}

export const getLang = req => {
  if (!req) {
    return config.locale || "en"
  }

  if (req.path && req.path.includes("/")) {
    const routeLang = req.path.split("/")[1]

    let lang = hasLang(routeLang)

    if (lang) {
      return lang
    }
  }

  if (
    req.headers["accept-language"] &&
    req.headers["accept-language"].includes(",")
  ) {
    const browserLang = req.headers["accept-language"]
      .split(",")[0]
      .toLowerCase()
    let lang = hasLang(browserLang)
    if (lang) {
      return lang
    }
  }

  return config.locale || "en"
}

export const getLocalesKeys = () => {
  return Object.keys(locales)
}

const trans = (key = "", params = {}, defaultLang) => {
  const lang = hasLang(defaultLang) || config.locale

  const trans = locales[lang]

  const ObjectDot = (obj, desc) => {
    var arr = desc.split(".")
    while (arr.length && (obj = obj[arr.shift()])) {}
    return obj
  }

  try {
    let text = ObjectDot(trans, key)

    Object.keys(params).forEach(
      key => (text = text.replace(`:${key}`, params[key]))
    )

    if (!text) {
      return "{$" + key + "}"
    }

    return text
  } catch (error) {
    return key
  }
}

export default trans
