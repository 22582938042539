import React from 'react'
import classNames from 'classnames'

const FormGroup = (props) => {
  const { title, children, className } = props
  const classes = classNames('mb-8', className)
  return (
    <div className={classes}>
      {title && <div className='text-black text-md mb-2'>{title}</div>}
      {children}
    </div>
  )
}

export default FormGroup
