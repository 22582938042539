import React from "react"
import Routes from "./_routes"
import { ApolloProvider } from "react-apollo"
import { AppProvider } from "~/src/context/AppContext"
import { Navbar } from "~/src/components"

// Bugsnag
// import { Bugsnag } from "libs/bugsnag"

// translations
import { setLocale } from "yup"
import trans from "./libs/translations"

// styles
import "./styles.scss"
import "planetyze-ui/styles.scss"

const App = ({ apollo, state }) => {
  setLocale(trans("validations", {}, state.lang))

  return (
      <ApolloProvider client={apollo}>
        <AppProvider state={state}>
          <Navbar />
          <Routes />
        </AppProvider>
      </ApolloProvider>
  )
}

export default App
