import React, { Component } from 'react'
import YouTube from 'react-youtube'
import { ieVersion } from '../../helpers'

const aspectRatio = (target) => {
  let width = window.outerWidth || 1200

  if (width < 800) {
    target.destroy()
  }

  if (width < 1200) {
    width = 1200
  }

  let height = width * 0.57

  target.setSize(width, height)
}

class Video extends Component {
  state = {
    isBrowser: false
  }

  componentDidMount () {
    this.setState({ isBrowser: true })
  }

  handleEnd = (event) => {
    event.target.playVideo()
  }

  handleReady = (event) => {
    event.target.mute()
    aspectRatio(event.target)

    window.addEventListener('resize', () => {
      aspectRatio(event.target)
    })
  }

  render () {
    if (!this.state.isBrowser) return null

    if (ieVersion()) return null

    const { src } = this.props
    const height = window.outerHeight
    const width = window.outerWidth

    if (width < 800) {
      return null
    }

    // default options
    const opts = {
      height,
      width,
      playerVars: {
        autoplay: 1,
        autohide: 1,
        modestbranding: 0,
        rel: 0,
        showinfo: 0,
        controls: 0,
        disablekb: 1,
        enablejsapi: 0,
        iv_load_policy: 3
      }
    }

    return (
      <YouTube
        videoId={src}
        opts={opts}
        onEnd={this.handleEnd}
        onReady={this.handleReady}
      />
    )
  }
}

export default Video
