import { Component } from 'react'

class ModalEvent extends Component {
  createEvent = () => {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  removeEvent = () => {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  componentDidMount () {
    this.createEvent()
  }

  componentWillUnmount () {
    this.removeEvent()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (typeof document !== 'undefined') {
      if (this.props.isOpen) {
        this.createEvent()
      }
      else {
        this.removeEvent()
      }
    }
  }

  handleClick = (event) => {
    if (!this.node) return false

    if (this.node.contains(event.target)) {
      return false
    }

    this.handleClose()
  }

  handleClose = () => {
    this.props.onChange()
  }
}

export default ModalEvent
