import React from 'react'
import FullStarIcon from 'react-ionicons/lib/MdStar'
import EmptyStarIcon from 'react-ionicons/lib/MdStarOutline'

const Stars = (props) => {
  const { rate, total, fontSize, className } = props

  const stars = []

  const emptyStars = total - parseInt(rate)
  const fullStars = total - emptyStars

  if (fullStars > 0) {
    for (let i = 1; i <= fullStars; i++) {
      stars.push(
        <FullStarIcon
          key={`fullStars-${i}`}
          fontSize={fontSize}
          className='fill-current text-yellow-dark'
        />
      )
    }
  }

  if (emptyStars > 0) {
    for (let i = 1; i <= emptyStars; i++) {
      stars.push(
        <EmptyStarIcon
          fontSize={fontSize}
          key={`emptyStars-${i}`}
          className='fill-current text-black'
        />
      )
    }
  }

  return <div className={className}>{stars.map((icon) => icon)}</div>
}

Stars.defaultProps = {
  rate: 0,
  total: 5,
  fontSize: '24px'
}

export default Stars
