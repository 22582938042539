import React from 'react'
import classNames from 'classnames'

const Select = ({
  type = 'text',
  error,
  value = false,
  items = [],
  ...props
}) => {
  // block appearance-none w-full bg-white border border-grey-light hover:border-grey rounded shadow leading-tight focus:outline-none focus:shadow-outline
  const classes = classNames('form-control select relative', {
    'form-control-error': error
  })

  return (
    <div className='mb-4'>
      <div className={classes}>
        <select
          className='border-0 w-full bg-white appearance-none outline-none h-12'
          defaultValue={value}
          {...props}>
          {items.map((item) => (
            <option key={`option-${item.id}`} value={item.value}>
              {item.children}
            </option>
          ))}
        </select>
        <div className='bg-white pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker'>
          <svg
            className='fill-current h-4 w-4'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'>
            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
          </svg>
        </div>
      </div>

      {error && <p className='text-red text-xs italic mt-2'>{error}</p>}
    </div>
  )
}

export default Select
