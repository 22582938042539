module.exports = {
  // Global
  japan: "日本",

  // Common Keywords
  common: {
    login: "登入",
    logout: "登出",
    blogs: "部落格",
    "not-found": "Page Not Found 404",
    orientation: "簡介",
    itineraries: "的行程",
    access: "交通",
    "read-more": "r閱讀更多",
    "show-all": "瀏覽所有",
    "no-results": "此頁面沒有結果",
    "search-no-results": "搜尋結果",
    "private-tours": "私人導遊行程",
    "private-tour-guides": "私人導遊"
  },

  // Pages
  home: {
    title: "日本 旅遊指南",
    description: "原創影片介紹日本最棒旅遊目的地"
  },

  destinations: {
    title: "最佳目的地"
  },

  guidebook: {
    overview: "簡介",
    "photos-videos": "照片及影片",
    itineraries: "的行程",
    reviews: "評價",
    nearby: "鄰近景點",
    address: "地址",
    website: "網站",
    "nearby-spots": "鄰近景點",
    "business-hours": "營業時間",
    price: "價格",
    phone: "電話",
    about: "關於",
    access: "交通"
  },

  itinerary: {
    day: "第:number天"
  },

  itineraries: {
    title: "遊行程"
  },

  blogs: {
    title: "關於:location的部落格與文章"
  },

  about: {
    title: "關於我們",
    description:
      "Planetyze是最大的日本旅遊指南網站，以原創影片，評論及文章為特色，涵蓋日本所有觀光景點。",
    lead: "您可觀看簡短的原創影片及閱讀其他遊客寫的評論探索您感興趣的觀光景點。"
  },

  company: {
    title: "公司",
    name: {
      label: "公司名稱",
      value: "Travelience Inc."
    },
    website: {
      label: "網站",
      value: "http://travelience.jp"
    },
    address: {
      label: "地址",
      value:
        "Maru-K Bldg 8F, 2-29-11 Asakusabashi, Taito-ku, Tokyo, Japan 111-0053"
    },
    phone: {
      label: "電話",
      value: "+81 (0)3-6830-0896"
    },
    established: {
      label: "成立於",
      value: "2013年2月"
    },
    ceo: {
      label: "CEO",
      value: "橋本直明"
    },
    services: {
      label: "服務",
      value: [
        "為參觀訪問日本的外國訪客提供專業導遊服務",
        "旅遊業務平台",
        "地方復興情報相關業務"
      ]
    }
  },

  contact: {
    title: "聯絡我們",
    description: "我們能幫你什麼嗎？",
    success: "感謝您的信息!"
  },

  auth: {
    profile: {
      title: "個人主頁",
      success: "個人主頁信息已更新"
    },
    password: {
      title: "密碼",
      success: "密碼已成功更新！"
    },
    reviews: {
      title: "評價"
    },
    wishlist: {
      title: "願望清單"
    }
  },

  login: {
    title: "登入",
    description: "組織您的研究和解鎖工具，如心願單。",
    "forgot-password": "忘記密碼？",
    "have-account": "已有帳號？",
    signup: "註冊"
  },

  // Website Blocks

  blocks: {
    destinations: "旅遊目的地",
    "popular-itineraries": "旅遊目的地",
    "popular-category": "流行:category",
    "blogs-articles-location": "關於:location的部落格與文章",
    categories: "類別",
    "related-itineraries": "相關路線",
    "all-category": "所有 :category"
  },

  buttons: {
    save: "儲存更新",
    submit: "提交",
    "play-video": "播放視頻",
    back: "背部",
    close: "關",
    login: "登入",
    "view-gallery": "查看圖庫",
    "wishlist-remove": "從願望清單中刪除",
    "wishlist-add": "加入願望清單",
    "view-all-location-itineraries": "查看全部:location條路線"
  },

  fields: {
    "full-name": "姓名",
    "first-name": "名",
    "last-name": "姓",
    "current-password": "當前密碼",
    "new-password": "新密碼",
    password: "密碼",
    email: "電子郵箱",
    message: "電子郵箱",
    search: "搜尋"
  },

  header: {
    destinations: "旅遊目的地",
    profile: "個人主頁",
    reviews: "評價",
    wishlist: "願望清單",
    logout: "登出",
    login: "登入"
  },

  footer: {
    planetyze: "Planetyze",
    "private-tours": "私人導遊行程",
    about: "關於我們",
    company: "公司",
    contact: "聯絡我們",
    press: "媒體曝光",
    "follow-us": "關注我們",
    youtube: "Youtube",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    pinterest: "Pinterest",
    legal: "法律",
    privacy: "隱私政策",
    terms: "服務條款"
  },

  // SEO
  seo: {
    home: {
      title: "Planetyze - 日本旅遊影片指南",
      description: "集結日本最佳旅遊行程，原創影片及相關主題文章"
    },
    location: {
      title: ":location - 城市旅遊指南",
      description: ":description"
    },
    guidebook: {
      title: ":guidebook - :location 城市旅遊指南",
      description: ":lead"
    },
    destinations: {
      title: "日本最棒人氣旅遊景點",
      description:
        "日本47道府縣的原創影片與主題文章介紹人氣目的地！！現在立即探索！"
    },
    itinerary: {
      title: ":itinerary - :location 旅遊指南",
      description: ":description"
    },
    "location-internal": {
      title: ":guidebook - :location 城市旅遊指南",
      description: ":location :guidebook"
    },
    "location-itineraries": {
      title: ":location 一日遊行程 - :location 城市旅遊指南",
      description:
        ":location專業打造一日遊行程名單。善用圖文影像行程表計畫你的旅遊行程！立即瀏覽所有行程表！"
    },
    "location-category": {
      title: ":location :category - :location 城市旅遊指南",
      description:
        ":location 最佳 :category 名單！欣賞影片，照片與相關資訊！現在即刻探索 :location!"
    },
    "location-tag": {
      title: ":location :tag - :location 城市旅遊指南",
      description:
        ":location 最佳 :tag 名單！欣賞影片，照片與相關資訊！現在即刻探索 :location !"
    },
    "location-blogs": {
      title: ":location 各種有趣主題文章與 - :location 城市旅遊指南 ",
      description: ":location獨特文化的相關趣味主題文章, 東京與京都."
    },
    blog: {
      title: ":blog - :location 城市旅遊指南",
      description: ":description"
    },
    category: {
      title: "日本 :category - 日本城市旅遊指南",
      description:
        "日本最佳 :category 名單！欣賞影片，照片與相關資訊！現在即刻探索日本"
    },
    tag: {
      title: "日本 :tag - 日本城市旅遊指南",
      description:
        "日本最佳 :tag 名單！欣賞影片，照片與相關資訊！現在即刻探索日本"
    },
    about: {
      title: "關於我們",
      description:
        "Planetyze 是日本最大旅遊指南，網羅日本所有景點原創影片、點評與觀光景點主題文章！"
    },
    company: {
      title: "公司",
      description:
        "Planetyze 是由Travelience Inc.所營運。, 電話: +81 3-6830-0896, 地址: Maru-K Bldg 8F, 2-29-11 Asakusabashi, Taito-ku, Tokyo, Japan 111-0053"
    },
    contact: {
      title: "聯繫我們",
      description:
        "需要聯繫Planetyze? 若您需要任何我們尚未提供的景點介紹或需要推薦相關行程，或是任何Planetyze合作詢問，請直接與我們聯繫！"
    },
    thread: {
      title: ":thread - :location 城市旅遊指南",
      description: ":description"
    },
    review: {
      title: ":review - :guidebook,:location城市旅遊指南",
      description: ":description"
    },
    login: {
      title: "登入",
      description: "組織您的研究和解鎖工具，如心願單。"
    },
    privacy: {
      title: "隱私權政策",
      description: "Planetyze 相當重視用戶隱私。請詳閱我們的隱私權政策。"
    },
    terms: {
      title: "服務條款",
      description: "服務條款適用於本服務的所有用戶。請詳閱我們的服務條款。"
    }
  },

  // Validations
  validations: {
    mixed: {
      default: "${path} is invalid",
      required: "${path} is a required field",
      oneOf: "${path} must be one of the following values: ${values}",
      notOneOf: "${path} must not be one of the following values: ${values}"
    },
    string: {
      length: "${path} must be exactly ${length} characters",
      min: "${path} must be at least ${min} characters",
      max: "${path} must be at most ${max} characters",
      matches: '${path} must match the following: "${regex}"',
      email: "${path} must be a valid email",
      url: "${path} must be a valid URL",
      trim: "${path} must be a trimmed string",
      lowercase: "${path} must be a lowercase string",
      uppercase: "${path} must be a upper case string"
    },
    number: {
      min: "${path} must be greater than or equal to ${min}",
      max: "${path} must be less than or equal to ${max}",
      lessThan: "${path} must be less than ${less}",
      moreThan: "${path} must be greater than ${more}",
      notEqual: "${path} must be not equal to ${notEqual}",
      positive: "${path} must be a positive number",
      negative: "${path} must be a negative number",
      integer: "${path} must be an integer"
    },
    date: {
      min: "${path} field must be later than ${min}",
      max: "${path} field must be at earlier than ${max}"
    },
    boolean: {},
    object: {
      noUnknown:
        "${path} field cannot have keys not specified in the object shape"
    },
    array: {
      min: "${path} field must have at least ${min} items",
      max: "${path} field must have less than or equal to ${max} items"
    }
  }
}
