import React from 'react'
import classNames from 'classnames'
import DropdownEvent from './DropdownEvent'
import { Link } from 'libs/router'

class Dropdown extends DropdownEvent {
  render () {
    const { items = {}, children, className } = this.props
    let styles = {}
    styles.container = classNames(
      'dropdown relative z-20 block mt-4 lg:inline-block text-white lg:mt-0',
      className
    )

    styles.menu = classNames(
      'dropdown-menu items-center border rounded shadow-md p-1 bg-white p-2 w-full pin-r',
      {
        hidden: !this.state.isOpen
      }
    )
    return (
      <div className={styles.container} ref={(node) => (this.node = node)}>
        <div
          className=' items-center cursor-pointer hover:underline'
          onClick={this.toggleDropdown}>
          {children}
          <svg
            className='fill-current h-4 w-4 ml-2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'>
            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
          </svg>
        </div>
        <div className={styles.menu}>
          {items.map(({ text, to = '/', ...item }, key) => (
            <Link
              to={to}
              {...item}
              key={`dropdown-${key}`}
              className='px-4 py-2 block text-black hover:bg-grey-lighter'>
              {text}
            </Link>
          ))}
        </div>
      </div>
    )
  }
}

export default Dropdown
