import React from 'react'
import { Helmet } from 'react-helmet'
import { getLocalizationMeta } from 'libs/seo'
import { Location } from '@reach/router'
import getContext from 'context'

const Head = (props) => {
  const { title, description, children } = props
  const { lang, t } = getContext()

  return (
    <Location>
      {({ location }) => {
        const path = location.pathname

        let titleTag = title

        if (path !== `/${lang}`) {
          titleTag = `${titleTag} | ${t('footer.planetyze')}`
        }

        return (
          <Helmet>
            <title>{titleTag}</title>
            {description && <meta name='description' content={description} />}
            {getLocalizationMeta({ lang, path }).map((item, key) => (
              <link key={`link-${key}`} {...item} />
            ))}
            {children}
          </Helmet>
        )
      }}
    </Location>
  )
}

export default Head
