const config = {
  graphql: "https://api.planetyze.com/graphql",
  domain: "https://planetyze.com",
  locale: "en",
  ga: "UA-37874007-5",
  bugsnag: "d15a953cdcdb6410068e1d2b35ce9041",
  locales: {
    en: "English",
    es: "Español",
    ja: "日本語",
    "zh-hans": "简体中文",
    "zh-hant": "繁體中文",
  },
};

// if (process.env.NODE_ENV === 'development') {
//   config.graphql = 'http://localhost:4141/graphql'
//   config.domain = 'http://localhost:3131'
// }
module.exports = config;
