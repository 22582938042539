/*eslint-disable*/
import React from "react"
import classNames from "classnames"
import getUTM from "../../utils/getUTM"

const Ads = props => {
  const {
    location = "Japan",
    className,
    medium = false,
    campaign = false
  } = props

  const params = getUTM("planetyze", medium + "-request_tour", campaign)
  let url = `https://triplelights.com/guides` + params

  if (location !== "Japan") {
    url =
      `https://triplelights.com/japan/${location.toLowerCase()}/guides` + params
  }

  const classes = classNames(
    "p-8 bg-orange-lightest border-t border-red text-center mb-20",
    className
  )

  return (
    <div className={classes}>
      <h3 className="text-2xl sm:text-4xl mb-6">
        Plan your trip to <span className="capitalize">{location}</span>
      </h3>
      <p className="text-lg mb-12 text-grey-darkest">
        Chat with a local tour guide who can help organize your trip.
      </p>
      <a
        href={url}
        target="_blank"
        className="bg-red-dark hover:bg-red text-white px-8 py-4 text-lg rounded">
        Request a Tour
      </a>
    </div>
  )
}

export default Ads
