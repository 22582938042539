import React, { Component } from "react"
import { Head, Loader, LoadMore } from "./components"
import { Spinner } from "../index"

class Layout extends Component {
  render() {
    const { children, title, description, className = "" } = this.props
    return (
      <div className={className}>
        <Layout.Head title={`v3: ${title}`} description={description} />
        {children}
        <Layout.Footer />
      </div>
    )
  }
}

Layout.Footer = () => null
Layout.Head = Head
Layout.Loader = Loader
Layout.LoadMore = LoadMore
Layout.Spinner = () => (
  <div className="text-center text-xl py-16">
    <Spinner />
  </div>
)

export default Layout
