module.exports = {
  // Global
  japan: "日本",

  // Common Keywords
  common: {
    login: "ログイン",
    logout: "ログアウト",
    blogs: "ブログ",
    "not-found": "ページが見つかりません",
    orientation: "概要",
    itineraries: "旅程プラン",
    access: "アクセス",
    "read-more": "もっと見る",
    "show-all": "全部見る",
    "no-results": "検索結果がありません",
    "search-no-results": "検索結果がありません",
    "private-tours": "プライベートツアー",
    "private-tour-guides": "プライベートツアーガイド"
  },

  // Pages
  home: {
    title: "日本の観光ガイド",
    description: "日本の人気観光地をオリジナルコンテンツで"
  },

  destinations: {
    title: "エリア"
  },

  guidebook: {
    overview: "概要",
    "photos-videos": "画像&動画",
    itineraries: "旅程プラン",
    reviews: "口コミ",
    nearby: "近くのスポット",
    address: "住所",
    website: "ウェブサイト",
    "nearby-spots": "近くのスポット",
    "business-hours": "営業時間",
    price: "料金",
    phone: "電話番号",
    about: "About",
    access: "アクセス"
  },

  itinerary: {
    day: "Day :number"
  },

  itineraries: {
    title: "旅程"
  },

  blogs: {
    title: ":locationのブログ＆記事"
  },

  about: {
    title: "プラネタイズについて",
    description: "プラネタイズは映像で見る日本最大級の国内旅行ガイドです",
    lead:
      "日本全国４７都道府県の旅程が手に入ります。観光スポットの動画や記事は全てオリジナルコンテンツです。"
  },

  company: {
    title: "運営会社",
    name: {
      label: "会社名",
      value: "株式会社トラベリエンス"
    },
    website: {
      label: "ウェブサイト",
      value: "http://travelience.jp"
    },
    address: {
      label: "住所",
      value: "〒111-0051 東京都台東区蔵前2-4-3 蔵前永谷ビル5階C"
    },
    phone: {
      label: "電話番号",
      value: "03-6830-0896"
    },
    established: {
      label: "設立",
      value: "2013年2月"
    },
    ceo: {
      label: "代表取締役社長",
      value: "橋本　直明"
    },
    services: {
      label: "事業内容",
      value: [
        "ツアーガイドマーケットプレイス<a href='https://triplelights.com/' target='_blank'>「トリプルライツ」</a>運営",
        "ガイドノウハウ動画のEラーニング<a href='https://learnwithcoach.com/ja' target='_blank'>「ラーンウィズコーチ」</a>運営",
        "<a href='https://learnwithcoach.com/ja/course/28' target='_blank'>通訳案内研修（登録研修機関研修）</a>実施"
      ]
    }
  },

  contact: {
    title: "お問い合わせ",
    description:
      "お問い合わせありがとうございます。 営業時間は平日10:00-18:00です。 24時間以内にご返答いたします。",
    success: "お問い合わせありがとうございました。"
  },

  auth: {
    profile: {
      title: "プロフィール",
      success: "プロフィールがアップデートされました。"
    },
    password: {
      title: "パスワード",
      success: "パスワードが変更されました。"
    },
    reviews: {
      title: "口コミ"
    },
    wishlist: {
      title: "ウィッシュリスト"
    }
  },

  login: {
    title: "サインイン",
    description:
      "ウィッシュリストなどをご利用いただくにはサインインが必要です。",
    "forgot-password": "パスワードをお忘れですか？",
    "have-account": "未登録の方はこちら",
    signup: "サインアップ"
  },

  // Website Blocks

  blocks: {
    destinations: "エリア",
    "popular-itineraries": "人気の旅程",
    "popular-category": "人気の:category",
    "blogs-articles-location": ":locationのブログ＆記事記事",
    categories: "カテゴリー",
    "related-itineraries": "その他の旅程",
    "all-category": "全ての:category"
  },

  buttons: {
    save: "保存する",
    submit: "送信する",
    "play-video": "動画再生",
    back: "戻る",
    close: "閉じる",
    login: "ログイン",
    "view-gallery": "フォトギャラリーを見る",
    "wishlist-remove": "ウィッシュリストから削除する",
    "wishlist-add": "ウィッシュリストに追加する",
    "view-all-location-itineraries": ":locationの全ての旅程を見る"
  },

  fields: {
    "full-name": "姓名",
    "first-name": "名",
    "last-name": "姓",
    "current-password": "現在のパスワード",
    "new-password": "新しいパスワード",
    password: "パスワード",
    email: "Eメール",
    message: "メッセージ",
    search: "検索する"
  },

  header: {
    destinations: "エリア",
    profile: "プロフィール",
    reviews: "口コミ",
    wishlist: "ウィッシュリスト",
    logout: "ログアウト",
    login: "ログイン"
  },

  footer: {
    planetyze: "プラネタイズ",
    "private-tours": "プライベートツアー",
    about: "プラネタイズについて",
    company: "運営会社",
    contact: "お問い合わせ",
    press: "プレスリリース",
    "follow-us": "Follow",
    youtube: "Youtube",
    facebook: "Facebook",
    twitter: "Twitter",
    instagram: "Instagram",
    pinterest: "Pinterest",
    legal: "Legal",
    privacy: "プライバシーポリシー",
    terms: "利用規約"
  },

  // SEO
  seo: {
    home: {
      title: "プラネタイズ　動画で見る日本最大級の国内旅行ガイド",
      description:
        "日本全国４７都道府県の旅程が手に入ります。観光スポットの動画や記事は全てオリジナルコンテンツです。"
    },
    location: {
      title:
        ":location - 動画で見る旅行ガイド - オリジナルの旅程や観光スポットの動画がたくさん",
      description:
        ":descriptionの旅程が手に入ります。観光スポットの動画や記事は全てオリジナルコンテンツです。"
    },
    guidebook: {
      title:
        ":guidebook, :location - オリジナルの旅程プランや観光スポットの動画がたくさん",
      description: ":lead"
    },
    destinations: {
      title: "日本全国の観光エリアリスト",
      description: "４７都道府県の観光スポットを見つけることができます。"
    },
    itinerary: {
      title: ":itinerary",
      description: ":description"
    },
    "location-internal": {
      title: ":location、:guidebookの旅程プラン",
      description:
        ":location、:guidebookの効率的な旅程プランをトラベルライターが作りました。"
    },
    "location-itineraries": {
      title: ":locationの旅程プラン",
      description:
        ":locationの効率的な旅程プランをトラベルライターが作りました。"
    },
    "location-category": {
      title: ":category - :locationの旅行ガイド",
      description:
        ":locationの:categoryに関する旅行ガイドです。オリジナルの旅程プランや観光スポットの動画がたくさん。"
    },
    "location-tag": {
      title: ":tag（:location） - 動画で見る:locationの旅行ガイド",
      description:
        ":tag（:location）に関する日本の旅行ガイドです。オリジナルの旅程プランや観光スポットの動画がたくさん。"
    },
    "location-blogs": {
      title: ":locationのブログ記事 - 動画で見る:locationの旅行ガイド",
      description: "トラベルライターが:locationのブログ記事を作りました。"
    },
    blog: {
      title: ":blog - 動画で見る:locationの旅行ガイド",
      description: ":description"
    },
    category: {
      title: ":category - 動画で見る:locationの旅行ガイド",
      description:
        ":categoryに関する日本の旅行ガイドです。オリジナルの旅程プランや観光スポットの動画がたくさん。"
    },
    tag: {
      title: ":tag - 動画で見る:locationの旅行ガイド",
      description:
        ":tagに関する日本の旅行ガイドです。オリジナルの旅程プランや観光スポットの動画がたくさん。"
    },
    about: {
      title: "プラネタイズについて",
      description:
        "プラネタイズは動画で見る日本最大級の国内旅行ガイドです。日本全国４７都道府県の観光スポットがそれぞれ1分の動画で見ることができます。また、各地のオススメの旅程が手に入ります。観光スポットの動画や記事は全てオリジナルコンテンツです。"
    },
    company: {
      title: "運営会社",
      description:
        "株式会社トラベリエンスがプラネタイズを運営しております。「新しい旅のスタイルを創る」をミッションに、新しい旅行ガイドブックを創っています。"
    },
    contact: {
      title: "お問い合わせ",
      description:
        "お問い合わせありがとうございます。営業日24時間以内にご返信をいたします。"
    },
    thread: {
      title: ":thread - :locationの旅行ガイド",
      description: ":description"
    },
    review: {
      title: ":review - :guidebook,:locationの旅行ガイド",
      description: ":description"
    },
    login: {
      title: "ログイン",
      description:
        "ウィッシュリストなどをご利用いただくにはログインが必要です。"
    },
    privacy: {
      title: "プライバシーポリシー",
      description: "プラネタイズのプライバシーポリシーです。"
    },
    terms: {
      title: "利用規約",
      description: "プラネタイズの利用規約です。"
    }
  },

  // Validations
  validations: {
    mixed: {
      default: "${path} が間違っています。",
      required: "${path} は入力が必須です。",
      oneOf: "${path} は: ${values}が必要です。",
      notOneOf: "${path} は: ${values}ではありません。"
    },
    string: {
      length: "${path}は ${length} 桁必要です",
      min: "${path} は少なくても ${min} 桁以上必要です。",
      max: "${path} は最大${max} 桁までです。",
      matches: '${path} は: "${regex}"と一致する必要があります。',
      email: "${path} は正しいEメールアドレスが必要です。",
      url: "${path} は正しいURLが必要です。",
      trim: "${path} must be a trimmed string",
      lowercase: "${path} must be a lowercase string",
      uppercase: "${path} must be a upper case string"
    },
    number: {
      min: "${path} は ${min}以上が必要です。",
      max: "${path} は ${max}以下が必要です。",
      lessThan: "${path} ${less}未満が必要です",
      moreThan: "${path} ${more}より上である必要があります。",
      notEqual: "${path} は${notEqual}と同じではいけません。",
      positive: "${path} must be a positive number",
      negative: "${path} must be a negative number",
      integer: "${path} must be an integer"
    },
    date: {
      min: "${path}は${min}より後でなくてはいけません。",
      max: "${path}は${max}より前でなくてはいけません。"
    },
    boolean: {},
    object: {
      noUnknown:
        "${path} field cannot have keys not specified in the object shape"
    },
    array: {
      min: "${path} field must have at least ${min} items",
      max: "${path} field must have less than or equal to ${max} items"
    }
  }
}
