import config from '~/config/app'

export const changeLinkLocalization = (path, langFrom, langTo = false) => {
  const baseUrl = path.replace('/' + langFrom, '')

  if (langTo) {
    return `${config.domain}/${langTo}${baseUrl}`
  }

  return `${config.domain}${baseUrl}`
}

export const getLocalizationMeta = ({ lang, path }) => {
  const meta = []

  meta.push({
    rel: 'canonical',
    href: `${config.domain}${path}`
  })

  meta.push({
    rel: 'alternate',
    href: changeLinkLocalization(path, lang, false),
    hrefLang: 'x-default'
  })

  Object.keys(config.locales).map((key) =>
    meta.push({
      rel: 'alternate',
      href: changeLinkLocalization(path, lang, key),
      hrefLang: key
    })
  )

  return meta
}
