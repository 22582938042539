import React from "react"
import { Layout } from "planetyze-ui"
import { Footer, Head } from "./components"
import { LWCAds, Ads } from "../index"
class AppLayout extends Layout {
  render() {
    const {
      children,
      title,
      description,
      src,
      className = "",
      pageType = "other",
      url = "unknown",
      headerMobileAds = true,
      location = "Japan"
    } = this.props

    return (
      <div className={className}>
        <Head title={title} description={description}>
          {src && <meta property="og:image" content={src} />}
        </Head>
        {pageType != "home" && pageType != "review" && (
          <Ads variant="header" className="bg-black py-8" />
        )}
        {children}
        <div class="block">
          <LWCAds className="my-16" />
        </div>
        <Ads variant="footer" className="mb-16" />
        <Footer />
      </div>
    )
  }
}

export default AppLayout
